import React from 'react';
import useTimeout from '../../../../hooks/useTimeout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getUcsGroupMembersThunk, ISentinelRender, selectGroupById } from '../../redux';
import { GroupFrom } from '../../types';

interface Props {
    sentinel: ISentinelRender;
    style: React.CSSProperties;
}

export default function Sentinel({ sentinel }: Props) {
    const { groupId } = sentinel;
    const group = useAppSelector((state) => selectGroupById(state, groupId));
    // const groupMembersLength = useAppSelector(state => state.contacts.groupToContactTable[groupId].length)
    const dispatch = useAppDispatch();

    useTimeout(() => {
        // console.log('yyyyyyy', 'sentinel mounted, request more members', group);
        const { groupFrom, nextCursor } = group;
        if (groupFrom !== GroupFrom.ucs) {
            return;
        }

        if (nextCursor === -1 || nextCursor >= 1000) {
            return;
        }

        dispatch(getUcsGroupMembersThunk(group));
    }, 600);

    return <div style={{ display: 'none' }}></div>;
}
