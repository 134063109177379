import React from 'react';
import { contactDetailDataSelector } from '../redux';
import { shallowEqual } from 'react-redux';
import '../../../assets/styles/main.scss';
import './ContactDetail.scss';
import { CONTACTS_NO_CONTACT_SELECTED } from '../../../resource';
import { useAppSelector } from '../../../store';
import ContactHead from './ContactHead';
import ContactControl from './ContactControl';
import ContactInfoList from './ContactInfoList';
import { useIsUserBlockedByIM } from '../../Chat';
import { isPendingContactJid } from '../utils/stringUtils';

export default function ContactDetail() {
    const contactDetail = useAppSelector(contactDetailDataSelector, shallowEqual);
    const { currentContact, presenceType, presenceText } = contactDetail;
    const isIMBlocked = useIsUserBlockedByIM({ jid: currentContact?.jid });

    console.log('currentContact ==>', currentContact);
    if (!currentContact || isPendingContactJid(currentContact.jid)) {
        return (
            <div className="contact-detail">
                <div className="contact-detail__empty" tabIndex={0}>
                    <i></i>
                    <p className="contact-detail__empty-txt">{CONTACTS_NO_CONTACT_SELECTED}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="contact-detail">
            <div className="contact-detail__content">
                <ContactHead
                    contact={currentContact}
                    presenceType={presenceType}
                    presenceText={presenceText}
                    isIMBlocked={isIMBlocked}
                />
                <ContactControl contactDetailData={contactDetail} isIMBlocked={isIMBlocked} />
                <ContactInfoList contact={currentContact} isIMBlocked={isIMBlocked} />
            </div>
        </div>
    );
}
