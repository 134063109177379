import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '../store/store-hooks';

export default function PhoneAudioElement(): JSX.Element {
    const { speakerVolume } = useAppSelector((state) => state.common.mediaConfig);
    // just phone use audio tag now
    const isShowAudio = useAppSelector((state) => {
        const { hasZoomPhonePlan, pwaFlagEnabled } = state.appModules['zoom-phone'];
        return hasZoomPhonePlan && pwaFlagEnabled;
    });
    const audioRef = useRef<HTMLAudioElement>();

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = speakerVolume;
        }
    }, [speakerVolume]);

    if (!isShowAudio) {
        return null;
    }
    return <audio id="pbx-audio" ref={audioRef} />;
}
