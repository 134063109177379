import React, { forwardRef, useEffect, useRef } from 'react';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { useAppDispatch } from '../../../../../store/store-hooks';
import { setModal } from '../../../../../store/modal/modal-store';
import { InviteContactModalType } from '../../../../ModalContainer/InviteContactModal/types';
import { DELETE_CONTACT, ADD_CAMERA_CONTROL_GROUP, REMOVE_CAMERA_CONTROL_GROUP } from '../../../../../resource';
import './ContactMemberOperation.scss';
import { isPendingContactJid } from '../../../utils/stringUtils';
import { deletePendingContactsThunk } from '../../../redux/contact-thunk';
import { CONTACT_MEMBER_OPTIONS_FROM, IContact_MemberOptions } from '../../../types';
import { cameraControlGroupHelper } from '../../..//utils/CameraControlGroupHelper';

interface Props extends OverlayInjectedProps {
    close(): void;
    ariaId: string;
    jid: string;
    groupId: string;
    name: string;
    email: string;

    from: CONTACT_MEMBER_OPTIONS_FROM;
    options: IContact_MemberOptions;
}

export default forwardRef<HTMLDivElement, Props>(function OperationMemberMenu({ close, ariaId, style, jid, name, email, options }, ref) {
    const isPendingContact = isPendingContactJid(jid);
    const dispatch = useAppDispatch();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { showFarEndCameraControlGroup, showDeleteContact } = options;

    const getAddToCameraControGroupOptions = () => {
        if (showFarEndCameraControlGroup) {
            if (cameraControlGroupHelper.hasExisted(jid)) {
                return {
                    title: REMOVE_CAMERA_CONTROL_GROUP,
                    onClick: () => {
                        close();
                        cameraControlGroupHelper.removeCameraControlGroup(jid);
                    },
                    className: '',
                };
            } else {
                return {
                    title: ADD_CAMERA_CONTROL_GROUP,
                    onClick: () => {
                        close();

                        dispatch(
                            setModal({
                                name: 'addToCameraControlGroup',
                                data: { name, jid },
                            }),
                        );
                    },
                    className: '',
                };
            }
        }

        return null;
    };

    const getDeleteContactOption = () => {
        if (showDeleteContact) {
            return {
                title: DELETE_CONTACT,
                onClick: (e: any) => {
                    close();
                    if (isPendingContact && email) {
                        dispatch(deletePendingContactsThunk(email));
                    } else {
                        dispatch(
                            setModal({
                                name: 'inviteContactModal',
                                data: {
                                    show: true,
                                    data: { jid, name, modalType: InviteContactModalType.DELETE_CONTACT },
                                },
                            }),
                        );
                    }

                    // keyboard click
                    // https://developer.mozilla.org/en-US/docs/Web/API/UIEvent/detail
                    if (e.detail === 0) {
                        // focus on list after delete contact
                        const contactListWrapper = document.getElementById('contactListWrapper');
                        contactListWrapper && contactListWrapper.focus();
                    }
                },
                className: isPendingContact ? '' : 'dangerous',
            };
        }

        return null;
    };

    useEffect(() => {
        buttonRef.current?.focus();
    }, []);

    const data = [getAddToCameraControGroupOptions(), getDeleteContactOption()].filter((_) => !!_);

    return (
        <div ref={ref} style={style} id={ariaId} className="contact-member-operation__menu">
            {data.map(({ title, onClick, className }) => {
                return (
                    <button
                        ref={buttonRef}
                        key={`${title}`}
                        className={`contact-member-opertation__menu-item ${className}`}
                        onClick={onClick}
                    >
                        {title}
                    </button>
                );
            })}
        </div>
    );
});
