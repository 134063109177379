import React, { useEffect, useState, useRef } from 'react';
import { IconChevronRightSmall } from '@zoom/icons-react';
import { Overlay } from 'react-bootstrap';
import type { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import classnames from 'classnames';
import PMIMenu from './PmiMenu';

interface IProps {
    close(): void;
    pmi: string;
    className?: string;
}

export default function PmiMenuTrigger({ close, pmi, className }: IProps) {
    const targetRef = useRef(null);
    const [show, setShow] = useState(false);

    const closePopup = () => {
        setShow(false);
        close();
    };

    const renderPopup = (props: OverlayInjectedProps) => {
        return <PMIMenu close={closePopup} {...props} />;
    };

    const onClickContainer = () => {
        setShow(!show);
    };

    useEffect(() => {
        return () => {
            closePopup();
        };
    }, []);

    const popperConfig = {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, -4],
                },
            },
        ],
    };

    const rootCls = classnames({
        [className]: className,
    });

    useEffect(() => {
        const onEnter = () => {
            setShow(true);
        };
        const onLeave = () => {
            setShow(false);
        };

        const domNode = targetRef.current;
        domNode.addEventListener('mouseenter', onEnter);
        domNode.addEventListener('mouseleave', onLeave);
        return () => {
            domNode.removeEventListener('mouseenter', onEnter);
            domNode.removeEventListener('mouseleave', onLeave);
        };
    }, []);

    return (
        <>
            <div
                className={rootCls}
                aria-expanded={show}
                aria-haspopup={true}
                onClick={onClickContainer}
                ref={targetRef}
            >
                <span className="pmi-number">{pmi}</span>
                <IconChevronRightSmall />
            </div>

            <Overlay
                target={targetRef.current}
                container={targetRef.current}
                placement="right-start"
                show={show}
                popperConfig={popperConfig}
            >
                {renderPopup}
            </Overlay>
        </>
    );
}
