import axios, { AxiosRequestConfig } from 'axios';
import httpRequest from './http';
import qs from 'querystring';
import { containsUnacceptedChars } from '../../utils/phone-number';
import { isNull } from 'lodash-es';

export interface ISearchContactResultItem_Original {
    firstName: string;
    lastName: string;
    displayName: string;
    picUrl: string;
    snsEmail: string;
    jid: string;
    type: number;
    isSameAccount: number;
    cellPhoneNumber: null;
    pbx: string | null; // json string;
    isMasterOrSub: number;
    bindWithMobile: string;
    sipPhone: string | null;
    countryCode: string | null;
    contactType: number;
    ibOptions: number;
    phoneNumbers: Array<{
        phoneNumber: string; // number;
        countryCode: string; // number
    }>;
}

export interface ISearchContactResultItem extends Omit<ISearchContactResultItem_Original, 'pbx'> {
    pbx: {
        ext?: number;
        dn?: Array<string>;
        cn?: string;
    };
}
interface ISearchContactProps {
    key: string; // partial or complete email
    contactType?: string | null; // search by fmtWorkPhoneNumber, extensionNumber and fmtDirectNumber(if account enable pbx), sipPhoneNumber(if account disable pbx and enable sipphone)
    phoneNumber?: null;
}

const DefaultSearchProps = {
    sourceType: 4, // from pwa
    contactType: [0, 2, 4, 5, 6, 7, 8].join(','),
};

export interface ISearchContactResult {
    contacts: Array<ISearchContactResultItem>;
}

interface ISearchRequestData {
    key: string;
    sourceType: number;
    contactType?: string;
    phoneNumber?: string;
}

export type ISearchContactResponse = ISearchRequestData & ISearchContactResult;

const getRequestData = (props: ISearchContactProps) => {
    const params: ISearchRequestData = { ...DefaultSearchProps, ...props };
    if (isNull(params.contactType)) {
        delete params.contactType;
    }
    if (isNull(params.phoneNumber)) {
        delete params.phoneNumber;
    } else {
        if (!containsUnacceptedChars(props.key)) {
            params.phoneNumber = props.key;
        }
    }

    return params;
};

/**
 * https://zoomvideo.atlassian.net/wiki/spaces/ZW/pages/1565425992/Design+for+ZOOM-231910+provide+contact+search+api+for+web+client+and+pwa
 */
export const createSearchContact = () => {
    const CancelToken = axios.CancelToken;
    let cancelSource = CancelToken.source();

    const cancelSearchContact = () => {
        cancelSource.cancel();
    };

    const checkIsCanceled = (error: Error) => {
        return axios.isCancel(error);
    };

    const searchContact = (params: ISearchContactProps): Promise<ISearchContactResponse> => {
        const url = '/im/contact/queryForWeb';
        const config: AxiosRequestConfig = {};
        const bodyData = getRequestData(params);
        const data = qs.stringify(bodyData as any);

        cancelSearchContact();

        cancelSource = CancelToken.source();
        config['cancelToken'] = cancelSource.token;

        return httpRequest
            .post(url, data, config)
            .then((response) => {
                const { errorCode, errorMessage, result } = response.data;
                if (errorCode === 201) {
                    // user not login
                    return Promise.reject(errorMessage);
                }
                if (errorCode !== 0) {
                    return Promise.reject({ message: errorMessage });
                }
                const contacts = result.map((item: ISearchContactResultItem_Original) => {
                    let { pbx } = item;
                    try {
                        pbx = JSON.parse(pbx || '{}');
                        return { ...item, pbx };
                    } catch (e) {
                        return item;
                    }
                });
                return {
                    contacts,
                    ...bodyData,
                };
            })
            .catch((error) => {
                if (!checkIsCanceled(error)) {
                    console.log(error.message);
                }
                return Promise.reject(error);
            });
    };

    return {
        cancelSearchContact,
        searchContact,
        checkIsCanceled,
    };
};
