/**
 * originated from media-sdk
 */

interface HIDDeviceLocal extends HIDDevice {
    hookStatus?: string;
    muted?: boolean;
    ring?: boolean;
}

const UsagePage = {
    LED: 0x08,
    TELEPHONY: 0x0b,
};

type TypeInputReportData = {
    productName: string;
    reportId: string;
    reportData: Uint8Array;
    eventName?: string;
    hookStatus?: string;
    isMute?: boolean;
    needDeclineCall: boolean;
};

export class HIDControl {
    static webHid: HID;
    static used: boolean;
    static async init(label: string, muted: boolean) {
        if (!window.navigator.hid || !window.navigator.hid.requestDevice) {
            return false;
        }
        const webHid = new HID((data: TypeInputReportData) => {
            switch (data.eventName) {
                case 'ondevicehookswitch':
                    if (this.used) {
                        this.onHookSwitched(data.hookStatus === 'on' && !data.needDeclineCall);
                    } else {
                        this.onHookSwitched(data.hookStatus === 'on');
                    }
                    break;
                case 'ondevicemuteswitch':
                    this.onMuteSwitched(data.isMute);
                    break;
                default:
                    break;
            }
        });
        const isOpen = await webHid.open({ label, muted, hookStatus: 'off' });
        if (isOpen) {
            this.webHid = webHid;
        }
        return isOpen;
    }

    static sendReport(event: string, val: any) {
        if (!this.webHid) {
            return;
        }
        switch (event) {
            case 'hookswitch':
                this.webHid.sendDeviceReport({ command: val ? 'onHook' : 'offHook' });
                break;
            case 'ring':
                this.webHid.sendDeviceReport({ command: val ? 'onRing' : 'offRing' });
                break;
            case 'mute':
                this.webHid.sendDeviceReport({ command: val ? 'muteOn' : 'muteOff' });
                break;
            default:
                break;
        }
    }

    static async destroy() {
        await this.webHid.close();
    }

    static onMuteSwitched(mute: boolean) {
        console.warn(mute, 'please define callback as `HIDControl.onMuteSwitched = (mute) => { // TODO }`');
    }

    static onHookSwitched(hookSwitch: boolean) {
        console.warn(hookSwitch, 'please define callback as `HIDControl.onHookSwitched = (hookSwitch) => { // TODO }`');
    }

    static onDeviceChanged(deviceName: string) {
        console.warn(
            deviceName,
            'please define callback as `HIDControl.onDeviceChanged = (deviceName) => { // TODO }`',
        );
    }
}

let isHIDOpening = false;

class HID {
    deviceUsage;
    deviceCommand;
    _deviceSelectHandler: (name: string) => void;
    inputReportRetFunc: (props: TypeInputReportData) => any;
    outputEventGenerators: Record<number, (val: boolean) => Uint8Array> = {};
    device: HIDDeviceLocal;
    constructor(callback: (props: TypeInputReportData) => void) {
        this.deviceUsage = {
            mute: { usageId: 0x080009, usageName: 'Mute' },
            offHook: { usageId: 0x080017, usageName: 'Off Hook' },
            ring: { usageId: 0x080018, usageName: 'Ring' },

            hookSwitch: { usageId: 0x0b0020, usageName: 'Hook Switch' },
            phoneMute: { usageId: 0x0b002f, usageName: 'Phone Mute' },
        };

        this.deviceCommand = {
            outputReport: {
                mute: { reportId: 0, usageOffset: -1 },
                offHook: { reportId: 0, usageOffset: -1 },
                ring: { reportId: 0, usageOffset: -1 },
            },
            inputReport: {
                hookSwitch: { reportId: 0, usageOffset: -1, isAbsolute: false },
                phoneMute: { reportId: 0, usageOffset: -1, isAbsolute: false },
            },
        };

        this.device = null;
        this.inputReportRetFunc = callback;
    }

    getHexByte(data: number | string) {
        let hex = Number(data).toString(16);
        while (hex.length < 2) hex = '0' + hex;
        return hex;
    }

    getHexByteStr(data: DataView) {
        let string = '';
        for (let i = 0; i < data.byteLength; ++i) {
            string += this.getHexByte(data.getUint8(i)) + ' ';
        }
        return string;
    }

    parseDeviceDescriptors() {
        try {
            this.outputEventGenerators = {};
            if (!(this.device && this.device.collections)) {
                return false;
            }

            const telephoneCollection = this.device.collections.find(
                (collection) => collection.usagePage === UsagePage.TELEPHONY,
            );
            if (!telephoneCollection || Object.keys(telephoneCollection).length === 0) {
                return false;
            }

            if (telephoneCollection.inputReports) {
                if (!this.parseInputReports(telephoneCollection.inputReports)) {
                    return false;
                } else {
                }
            }

            if (telephoneCollection.outputReports) {
                if (!this.parseOutputReports(telephoneCollection.outputReports)) {
                    return false;
                } else {
                    return true;
                }
            }
        } catch (e) {
            console.error('parseDeviceDescriptors error:' + JSON.stringify(e, null, '    '));
        }
        return false;
    }

    parseInputReports(inputReports: Array<HIDReportInfo>) {
        inputReports.forEach((report) => {
            if (!report.items.length || report.reportId === undefined) {
                return;
            }

            let usageOffset = 0;

            report.items.forEach((item) => {
                if (
                    item.usages === undefined ||
                    item.reportSize === undefined ||
                    item.reportCount === undefined ||
                    item.isAbsolute === undefined
                ) {
                    return;
                }

                item.usages.forEach((usage, i) => {
                    switch (usage) {
                        case this.deviceUsage.hookSwitch.usageId:
                            this.deviceCommand.inputReport['hookSwitch'] = {
                                reportId: report.reportId,
                                usageOffset: usageOffset + i * item.reportSize,
                                isAbsolute: item.isAbsolute,
                            };
                            break;
                        case this.deviceUsage.phoneMute.usageId:
                            this.deviceCommand.inputReport['phoneMute'] = {
                                reportId: report.reportId,
                                usageOffset: usageOffset + i * item.reportSize,
                                isAbsolute: item.isAbsolute,
                            };
                            break;
                        default:
                            break;
                    }
                });

                usageOffset += item.reportCount * item.reportSize;
            });
        });

        return true;
    }

    parseOutputReports(outputReports: Array<HIDReportInfo>) {
        outputReports.forEach((report) => {
            if (!report.items.length || report.reportId === undefined) {
                return;
            }

            let usageOffset = 0;
            const usageOffsetMap = new Map();

            report.items.forEach((item) => {
                if (item.usages === undefined || item.reportSize === undefined || item.reportCount === undefined) {
                    return;
                }

                item.usages.forEach((usage, i) => {
                    switch (usage) {
                        case this.deviceUsage.mute.usageId:
                            this.deviceCommand.outputReport['mute'] = {
                                reportId: report.reportId,
                                usageOffset: usageOffset + i * item.reportSize,
                            };
                            usageOffsetMap.set(usage, usageOffset + i * item.reportSize);
                            break;
                        case this.deviceUsage.offHook.usageId:
                            this.deviceCommand.outputReport['offHook'] = {
                                reportId: report.reportId,
                                usageOffset: usageOffset + i * item.reportSize,
                            };
                            usageOffsetMap.set(usage, usageOffset + i * item.reportSize);
                            break;
                        case this.deviceUsage.ring.usageId:
                            this.deviceCommand.outputReport['ring'] = {
                                reportId: report.reportId,
                                usageOffset: usageOffset + i * item.reportSize,
                            };
                            usageOffsetMap.set(usage, usageOffset + i * item.reportSize);
                            break;
                        default:
                            break;
                    }
                });

                usageOffset += item.reportCount * item.reportSize;
            });

            const reportLength = usageOffset;
            for (const [usage, offset] of usageOffsetMap) {
                this.outputEventGenerators[usage] = (val: boolean) => {
                    const reportData = new Uint8Array(reportLength / 8);

                    if (offset >= 0 && val) {
                        const byteIndex = Math.trunc(offset / 8);
                        const bitPosition = offset % 8;
                        reportData[byteIndex] = 1 << bitPosition;
                    }

                    return reportData;
                };
            }
        });

        let mute, ring, hook;
        for (const item in this.outputEventGenerators) {
            let newItem = this.getHexByte(item);
            newItem = '0x0' + newItem;
            if (this.deviceUsage.mute.usageId === Number(newItem)) {
                mute = this.outputEventGenerators[this.deviceUsage.mute.usageId];
            } else if (this.deviceUsage.offHook.usageId === Number(newItem)) {
                hook = this.outputEventGenerators[this.deviceUsage.offHook.usageId];
            } else if (this.deviceUsage.ring.usageId === Number(newItem)) {
                ring = this.outputEventGenerators[this.deviceUsage.ring.usageId];
            }
        }
        if (!mute && !ring && !hook) {
            return false;
        } else {
            return true;
        }
    }

    async open(data: { label: string; muted: boolean; hookStatus: string }) {
        const pairedDevices = await navigator.hid.getDevices();
        if (pairedDevices.length) {
            this.device = pairedDevices.find((device) => data.label.includes(device.productName));
        }
        if (!this.device) {
            try {
                const devices = await navigator.hid.requestDevice({
                    filters: [{ usagePage: UsagePage.TELEPHONY }],
                });
                if (devices.length) {
                    this.device = devices[0];
                }
            } catch (error) {
                console.error('hid requestDevice error', error);
                isHIDOpening = false;
                return false;
            }
        }
        if (!this.device) {
            return false;
        }
        if (isHIDOpening) {
            return false;
        }

        isHIDOpening = true;
        try {
            if (this.device.opened) {
                await this.device.close();
            }
            await this.device.open();
            isHIDOpening = false;
            if (!this.parseDeviceDescriptors()) {
                return false;
            }
            this.device.oninputreport = this.handleInputReport.bind(this);
            this.resetState({ muted: data.muted, hookStatus: data.hookStatus });
            HIDControl.onDeviceChanged(this.device.productName);
            return true;
        } catch (e) {
            console.error('error content:' + e);
        }
        isHIDOpening = false;
        return false;
    }

    async close() {
        try {
            this.resetState({ muted: false, hookStatus: 'on' });
            if (!this.device) {
                return;
            }
            if (this.device && this.device.opened) {
                await this.device.close();
            }
            this.device.oninputreport = null;
            this.device = null;
        } catch (e) {
            console.error(e);
        }
    }

    resetState({ muted, hookStatus }: { muted: boolean; hookStatus: string }) {
        if (!this.device || !this.device.opened) {
            return;
        }
        this.device.hookStatus = hookStatus;
        this.device.muted = muted;
        this.device.ring = false;
        this.sendDeviceReport({
            command: hookStatus === 'off' ? 'offHook' : 'onHook',
        });
        this.sendDeviceReport({ command: muted ? 'muteOn' : 'muteOff' });
    }

    async sendDeviceReport(data: { command: string }) {
        if (!data || !data.command || !this.device || !this.device.opened) {
            return;
        }

        console.log('[hid_msg] send -->', data);

        if (data.command === 'muteOn' || data.command === 'muteOff') {
            if (!this.outputEventGenerators || !this.outputEventGenerators[this.deviceUsage.mute.usageId]) {
                return;
            }
        } else if (data.command === 'onHook' || data.command === 'offHook') {
            if (!this.outputEventGenerators || !this.outputEventGenerators[this.deviceUsage.offHook.usageId]) {
                return;
            }
        } else if (data.command === 'onRing' || data.command === 'offRing') {
            if (!this.outputEventGenerators || !this.outputEventGenerators[this.deviceUsage.ring.usageId]) {
                return;
            }
        }

        let reportId = 0;
        let oldOffHook;
        let newOffHook;
        let newMuted;
        let newRing;
        let offHookReport;
        let muteReport;
        let ringReport;
        let reportData: Uint8Array;
        switch (data.command) {
            case 'muteOn':
            case 'muteOff':
                reportId = this.deviceCommand.outputReport['mute'].reportId;
                break;
            case 'onHook':
            case 'offHook':
                reportId = this.deviceCommand.outputReport['offHook'].reportId;
                break;
            case 'onRing':
            case 'offRing':
                reportId = this.deviceCommand.outputReport['ring'].reportId;
                break;
            default:
                return;
        }

        if (reportId === 0) {
            return;
        }

        const oldMuted = this.device.muted;
        if (this.device.hookStatus === 'on') {
            oldOffHook = true;
        } else if (this.device.hookStatus === 'off') {
            oldOffHook = false;
        } else {
            return;
        }
        const oldRing = this.device.ring;

        switch (data.command) {
            case 'muteOn':
                newMuted = true;
                this.device.muted = true;
                break;
            case 'muteOff':
                newMuted = false;
                this.device.muted = false;
                break;
            case 'onHook':
                newOffHook = true;
                this.device.hookStatus = 'on';
                break;
            case 'offHook':
                newOffHook = false;
                this.device.hookStatus = 'off';
                break;
            case 'onRing':
                newRing = true;
                this.device.ring = true;
                break;
            case 'offRing':
                newRing = false;
                this.device.ring = false;
                break;
            default:
                return;
        }

        if (this.outputEventGenerators[this.deviceUsage.mute.usageId]) {
            if (newMuted === undefined) {
                muteReport = this.outputEventGenerators[this.deviceUsage.mute.usageId](oldMuted);
            } else {
                muteReport = this.outputEventGenerators[this.deviceUsage.mute.usageId](newMuted);
            }
        }

        if (this.outputEventGenerators[this.deviceUsage.offHook.usageId]) {
            if (newOffHook === undefined) {
                offHookReport = this.outputEventGenerators[this.deviceUsage.offHook.usageId](oldOffHook);
            } else {
                offHookReport = this.outputEventGenerators[this.deviceUsage.offHook.usageId](newOffHook);
            }
        }

        if (this.outputEventGenerators[this.deviceUsage.ring.usageId]) {
            if (newRing === undefined) {
                ringReport = this.outputEventGenerators[this.deviceUsage.ring.usageId](oldRing);
            } else {
                ringReport = this.outputEventGenerators[this.deviceUsage.ring.usageId](newRing);
            }
        }

        if (reportId === this.deviceCommand.outputReport['mute'].reportId) {
            if (reportData) {
                for (const [i, data] of muteReport.entries()) {
                    reportData[i] |= data;
                }
            } else {
                reportData = new Uint8Array(muteReport);
            }
        }

        if (reportId === this.deviceCommand.outputReport['offHook'].reportId) {
            if (!reportData) {
                reportData = new Uint8Array(offHookReport);
            } else {
                for (const [i, data] of offHookReport.entries()) {
                    reportData[i] |= data;
                }
            }
        }

        if (reportId === this.deviceCommand.outputReport['ring'].reportId) {
            if (!reportData) {
                reportData = new Uint8Array(ringReport);
            } else {
                for (const [i, data] of ringReport.entries()) {
                    reportData[i] |= data;
                }
            }
        }

        console.log('[hid_msg] send actual -->', reportId, reportData);
        await this.device.sendReport(reportId, reportData);
    }

    async sendReplyReport(inputReportId: number, curOffHook: boolean, curMuted: boolean) {
        console.log('[hid_msg] send reply -->', inputReportId, curOffHook, curMuted);
        let reportId = 0;
        if (this.deviceCommand.outputReport['offHook'].reportId === this.deviceCommand.outputReport['mute'].reportId) {
            reportId = this.deviceCommand.outputReport['offHook'].reportId;
        } else if (inputReportId === this.deviceCommand.inputReport['hookSwitch'].reportId) {
            reportId = this.deviceCommand.outputReport['offHook'].reportId;
        } else if (inputReportId === this.deviceCommand.inputReport['phoneMute'].reportId) {
            reportId = this.deviceCommand.outputReport['mute'].reportId;
        }
        if (!this.device || !this.device.opened) {
            return;
        }

        if (reportId === 0 || curOffHook === undefined || curMuted === undefined) {
            return;
        }

        let reportData;
        let muteReport;
        let offHookReport;
        let ringReport;

        if (this.deviceCommand.outputReport['offHook'].reportId === this.deviceCommand.outputReport['mute'].reportId) {
            muteReport = this.outputEventGenerators[this.deviceUsage.mute.usageId](curMuted);
            offHookReport = this.outputEventGenerators[this.deviceUsage.offHook.usageId](curOffHook);
            reportData = new Uint8Array(offHookReport);
            for (const [i, data] of muteReport.entries()) {
                reportData[i] |= data;
            }
        } else if (reportId === this.deviceCommand.outputReport['offHook'].reportId) {
            offHookReport = this.outputEventGenerators[this.deviceUsage.offHook.usageId](curOffHook);
            reportData = new Uint8Array(offHookReport);
        } else if (reportId === this.deviceCommand.outputReport['mute'].reportId) {
            muteReport = this.outputEventGenerators[this.deviceUsage.mute.usageId](curMuted);
            reportData = new Uint8Array(muteReport);
        } else if (reportId === this.deviceCommand.outputReport['ring'].reportId) {
            ringReport = this.outputEventGenerators[this.deviceUsage.ring.usageId](this.device.ring);
            reportData = new Uint8Array(ringReport);
        }
        console.log('[hid_msg] send actual reply -->', reportId, reportData);
        await this.device.sendReport(reportId, reportData);
    }

    handleInputReport(event: HIDInputReportEvent) {
        try {
            console.log('[hid_msg] receive <--', event);
            const { data, device, reportId } = event;
            if (reportId === 0) {
                return;
            } else {
            }

            const inputReport = this.deviceCommand.inputReport;
            if (reportId !== inputReport['hookSwitch'].reportId && reportId !== inputReport['phoneMute'].reportId) {
                return;
            }

            let hookStatusChange = false;
            const prevHookStatus = this.device.hookStatus;
            let muteStatusChange = false;
            let needDeclineCall = false;

            const reportData = new Uint8Array(data.buffer);
            let needReply = false;

            const inputReportData: TypeInputReportData = {
                productName: device.productName,
                reportId: this.getHexByte(reportId),
                reportData: reportData,
                needDeclineCall: false,
            };

            if (reportId === inputReport['hookSwitch'].reportId) {
                const item = inputReport['hookSwitch'];
                const byteIndex = Math.trunc(item.usageOffset / 8);
                const bitPosition = item.usageOffset % 8;
                const usageOn = (data.getUint8(byteIndex) & (0x01 << bitPosition)) !== 0;
                if (inputReport['hookSwitch'].isAbsolute) {
                    if (this.device.hookStatus === 'off') {
                        if (usageOn) {
                            // accept call
                            this.device.hookStatus = 'on';
                            hookStatusChange = true;
                        } else {
                            // decline call
                            needDeclineCall = true;
                        }
                    } else if (this.device.hookStatus === 'on') {
                        if (usageOn) {
                            // decline call
                            needDeclineCall = true;
                        } else {
                            if (!HIDControl.used) {
                                this.device.hookStatus = 'off';
                            }
                            hookStatusChange = true;
                        }
                    }
                } else if (usageOn) {
                    this.device.hookStatus = this.device.hookStatus === 'off' ? 'on' : 'off';
                    hookStatusChange = true;
                }
            }

            // let oldMute = that.device.muted;
            if (reportId === inputReport['phoneMute'].reportId) {
                const item = inputReport['phoneMute'];
                const byteIndex = Math.trunc(item.usageOffset / 8);
                const bitPosition = item.usageOffset % 8;
                const usageOn = (data.getUint8(byteIndex) & (0x01 << bitPosition)) !== 0;
                if (inputReport['phoneMute'].isAbsolute) {
                    if (this.device.muted !== usageOn) {
                        this.device.muted = usageOn;
                        muteStatusChange = true;
                    }
                } else if (usageOn) {
                    this.device.muted = !this.device.muted;
                    muteStatusChange = true;
                }
            }

            if (hookStatusChange || needDeclineCall) {
                if (this.device.ring) {
                    inputReportData.eventName = 'ondevicehookswitch';
                    inputReportData.hookStatus = this.device.hookStatus;
                    inputReportData.needDeclineCall = needDeclineCall;
                } else {
                    this.device.hookStatus = prevHookStatus;
                    needReply = true;
                }
            }

            if (muteStatusChange) {
                inputReportData.eventName = 'ondevicemuteswitch';
                inputReportData.isMute = this.device.muted;
            }

            this.inputReportRetFunc && this.inputReportRetFunc(inputReportData);

            if (needReply && (hookStatusChange || muteStatusChange)) {
                let newOffHook;
                if (this.device.hookStatus === 'on') {
                    newOffHook = true;
                } else if (this.device.hookStatus === 'off') {
                    newOffHook = false;
                } else {
                    return;
                }
                this.sendReplyReport(reportId, newOffHook, this.device.muted);
            }
        } catch (e) {
            console.error(e);
        }
    }
}
