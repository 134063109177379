export const WebimStatus = {
    INIT: 'INIT',
    CONNECTING: 'CONNECTING',
    CONNFAIL: 'CONNFAIL',
    AUTHENTICATING: 'AUTHENTICATING',
    AUTHFAIL: 'AUTHFAIL',
    CONNECTED: 'CONNECTED',
    DISCONNECTING: 'DISCONNECTING',
    DISCONNECTED: 'DISCONNECTED',
    RECONNECTING: 'RECONNECTING',
    CONNTIMEOUT: 'CONNTIMEOUT',
    ERROR: 'ERROR',
};

export const WebimStatusErrorType = {
    CONFLICT: 'conflict', // the same account has been loged in in other place
    UNKNOWN: 'unknown',
};
