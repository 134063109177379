import React from 'react';
import dayjs from 'dayjs';
import { CalendarEvent, MeetingItem } from '../Meetings/types';
import UpcomingActions from './UpcomingActions';
interface IProp {
    data: MeetingItem | CalendarEvent;
    toView(id: string): void;
}

export default function UpcomingItem({ data, toView }: IProp): JSX.Element {
    const { topic, startTime, duration } = data;
    const start = dayjs.unix(startTime).format('HH:mm');
    const end = dayjs.unix(startTime + duration * 60).format('HH:mm');

    return (
        <div className="upcoming__item">
            <div className="upcoming__item-info" title={topic} onClick={() => toView(data.id)}>
                <p className="upcoming__item-topic">{topic}</p>
                <p className="upcoming__item-time">{`${start} - ${end}`}</p>
            </div>
            <UpcomingActions data={data} />
        </div>
    );
}
