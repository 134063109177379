import React, { useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import StartMeetingOptionPopuup from './StartMeetingOptionPopuup';
import './start-meeting-label.scss';
import { IconChevronDownSmall } from '@zoom/icons-react';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';

interface IProps {
    label: string;
}

export default function StartMeetingLabel({ label }: IProps) {
    const [show, setShow] = useState(false);
    const ariaId = 'start-meeting-panel';

    const onToggle = () => {
        setShow(!show);
    };

    const closePouup = () => {
        setShow(false);
    };

    const rednerPopup = (props: OverlayInjectedProps) => {
        return <StartMeetingOptionPopuup close={closePouup} ariaId={ariaId} {...props} />;
    };

    const popperConfig = {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 4],
                },
            },
        ],
    };

    return (
        <div className="start-label">
            <span id="start-meeting">{label}</span>
            <OverlayTrigger
                overlay={rednerPopup}
                placement="bottom"
                trigger={['click']}
                show={show}
                onToggle={onToggle}
                rootClose={true}
                popperConfig={popperConfig}
            >
                <button
                    className="start-label__icon"
                    aria-labelledby="start-meeting"
                    aria-controls={ariaId}
                    aria-haspopup={true}
                    aria-expanded={show}
                >
                    <IconChevronDownSmall aria-hidden={true} />
                </button>
            </OverlayTrigger>
        </div>
    );
}
