import React, { useEffect, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import styles from './AppLoading.module.scss';
import { LOADING } from './features/Phone/LanguageResource';
import { sendChromeOSAdminPolicyToServer } from './utils/chrome-policy';

import { isTeslaMode } from './utils';
const Logo = lazy(() => import('./features/Index/Logo'));
const LogoTesla = lazy(() => import('./features/Index/LogoTesla'));

interface IProps {
    redirectToApp(): void;
}

const AppLoading = ({ redirectToApp }: IProps) => {
    useEffect(() => {
        sendChromeOSAdminPolicyToServer().then(redirectToApp);
    }, []);

    return (
        <div className={styles.appLoading}>
            <Suspense fallback={null}>
                {isTeslaMode() ? <LogoTesla /> : <Logo />}
            </Suspense>
            <div className={styles.progressBar}>
                <div className={styles.progressThumb}></div>
            </div>
            <p className={styles.loading}>{LOADING}</p>
        </div>
    );
};

export const renderLoading = (props: IProps) => {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root && root.render(<AppLoading {...props} />);
};
