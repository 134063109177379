import { getChromeOSAdminPolicy } from './get-policy';
import httpRequest from '../../services/http/http';
import qs from 'querystring';
import { isUndefined } from 'lodash-es';
import { cookies } from '../Cookies/cookie';
import { getSubDomain } from '../meeting-url';

const userBehaviorDataUrl = '/wc/pwa-data';

const Cookie_Key_Map_For_Join = {
    SSOLoginRenameDisabled: 'srd',
    GoogleLoginRenameDisabled: 'grd',
    SetAccountIDsRestrictedToJoin: 'saids',
};

const Cookie_Key_Map_For_Signin = {
    DisableGoogleLogin: 'dg',
    DisableFacebookLogin: 'df',
    DisableLoginWithSSO: 'ds',
    DisableLoginWithEmail: 'de',
    DisableAppleLogin: 'da',
    ForceLoginWithSSO: 'fs',
    SetSSOURL: 'ssu',
};

export interface IUserBehaviorData {
    SetAccountIDsRestrictedToJoin: string;
    GoogleLoginRenameDisabled: boolean;
    SSOLoginRenameDisabled: boolean;
}

// https://zoomvideo.atlassian.net/browse/ZOOM-525810
const setChromePolicyToCookie = (policy: Record<string, string>) => {
    const prop = {} as Record<string, string>;
    Object.entries(Cookie_Key_Map_For_Signin).forEach(([key, value]) => {
        const policyValue = policy[key];
        if (!isUndefined(policyValue)) {
            prop[value] = policyValue;
        }
    });

    cookies.set('pwa_ply_2', JSON.stringify(prop), {
        domain: getSubDomain(window.location.hostname),
        path: '/',
        sameSite: 'strict',
        secure: true,
        maxAge: 60 * 60 * 24 * 15,
    });
};

export const sendChromeOSAdminPolicyToServer = async (): Promise<boolean> => {
    const policy = await getChromeOSAdminPolicy();
    if (!policy) {
        return true;
    }

    const prop = {} as Record<string, any>;
    Object.entries(Cookie_Key_Map_For_Join).forEach(([key, value]) => {
        const policyValue = policy[key];
        if (!isUndefined(policyValue)) {
            prop[value] = policyValue;
        }
    });

    const payload = qs.stringify({
        policy: JSON.stringify(prop),
    });

    setChromePolicyToCookie(policy);

    try {
        const response = await httpRequest.post(userBehaviorDataUrl, payload);
        const {
            data: { status },
        } = response;
        return status;
    } catch (_e) {
        return false;
    }
};
