import React from 'react';

import { CalendarEvent, MeetingItem } from '../Meetings/types';
import Button from '../../../components/Button/Button';
import { useAppDispatch } from '../../../store/store-hooks';
import { setSelectedMeetingItemId } from '../Meetings/redux/meetings-store';
import { RoutePath } from '../../../routes/routePath';
import { useHistory } from 'react-router-dom';
import useMeetingItem from '../Meetings/useMeetingItem';
import useMeetingItemState from '../Meetings/useMeetingItemState';

import { View_Text } from '../LanguageResource';

interface IProps {
    data: MeetingItem | CalendarEvent;
}

export default function UpcomingActionsTesla({ data }: IProps): JSX.Element {
    const { id } = data;
    const dispatch = useAppDispatch();
    const history = useHistory();

    const meetingItemProperties = useMeetingItem({ item: data });
    const {
        canStart,
        startTxt,
        startHandler,
        canJoin,
        joinTxt,
        joinHandler,
        canViewOnZoomEvent,
        viewOnZoomEventTxt,
        viewOnZoomEventHandler,
    } = meetingItemProperties;
    const { disableJoin, disableStart } = useMeetingItemState(meetingItemProperties);
    const toView = () => {
        dispatch(setSelectedMeetingItemId(id));
        history.push(RoutePath.Meetings);
    };

    // if it's only a calendar event and not related with any meetings, we return nothing.
    let show = true;
    let btnTxt = '';
    let btnHandler = null;
    let btnDisabled = false;
    const btnType = Button.Normal;
    if (canStart) {
        btnTxt = startTxt;
        btnHandler = startHandler;
        btnDisabled = disableStart;
    } else if (canJoin) {
        btnTxt = joinTxt;
        btnHandler = joinHandler;
        btnDisabled = disableJoin;
    } else if (canViewOnZoomEvent) {
        btnTxt = viewOnZoomEventTxt;
        btnHandler = viewOnZoomEventHandler;
    } else {
        show = false;
        btnTxt = View_Text;
        btnHandler = toView;
    }

    if (!show) {
        return null;
    }

    return (
        <div className="upcoming__actions">
            <Button
                size={Button.Size48}
                type={btnType}
                onClick={btnHandler}
                extraCls="upcoming-btn-tesla"
                disabled={btnDisabled}
            >
                {btnTxt}
            </Button>
        </div>
    );
}
