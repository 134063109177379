import React, { forwardRef } from 'react';
import copy from 'copy-to-clipboard';
import type { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { useAppSelector } from '../../store';
import { pmiMeetingSettingSelector } from '../Meetings/Meetings/redux/meetings-selector';
import useMeetingItem from '../Meetings/Meetings/useMeetingItem';
import './PmiMenu.scss';
import { getScheduleEditPagePath } from '../../utils/meeting-url';
import { Copy_Invitation_Text } from '../Meetings/LanguageResource';
import { COPY_PMI_ID_TEXT, PMI_SETTINGS_TEXT } from '../../resource';
import { isTeslaMode, openTab } from '../../utils';

interface IPMIMenuProps extends OverlayInjectedProps {
    close(): void;
}

export default forwardRef<HTMLDivElement, IPMIMenuProps>(function PmiMenu({ style, close }, ref) {
    const pmiSetting = useAppSelector(pmiMeetingSettingSelector);
    const pmiSettingProperties = useMeetingItem({ item: pmiSetting });
    const { meetingNo, copyHandler } = pmiSettingProperties || {};
    const isTesla = isTeslaMode();

    const copyId = () => {
        copy(meetingNo);
        close();
    };

    const copyInvitation = () => {
        copyHandler()
            .then((invitation) => {
                copy(invitation);
            })
            .finally(close);
    };

    const goToPmiSetting = () => {
        openTab(getScheduleEditPagePath(meetingNo, false));
        close();
    };

    return (
        <div ref={ref} style={style} className="start-pmi__menu-wrapper" role="none">
            <ul className="start-pmi__menu">
                {meetingNo ? (
                    <li onClick={copyId} className="start-pmi__menu-item">
                        {COPY_PMI_ID_TEXT}
                    </li>
                ) : null}
                {copyHandler ? (
                    <li onClick={copyInvitation} className="start-pmi__menu-item">
                        {Copy_Invitation_Text}
                    </li>
                ) : null}
                {!isTesla ? (
                    <li onClick={goToPmiSetting} className="start-pmi__menu-item">
                        {PMI_SETTINGS_TEXT}
                    </li>
                ) : null}
            </ul>
        </div>
    );
});
