import React from 'react';
import { Tabs } from '../../components/Tabs';
import Meetings from './Meetings/Meetings';
import MeetingDetail from './Meetings/ItemDeatil/ItemDetail';
import RefreshIcon from './RefreshIcon';
import { TabKey, setSelectedTabKey } from './Meetings/redux/meetings-store';
import { Upcoming_Text } from './LanguageResource';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';

export default function MeetingsContainer() {
    const dispatch = useAppDispatch();
    const { selectedTabKey } = useAppSelector((state) => state.meetings);
    const TabBarNode = ({ text }: any) => {
        return <div className="meetings-container-panel__tab_bar">{text}</div>;
    };
    const tabPanes = [
        {
            text: Upcoming_Text,
            key: TabKey.upcoming,
            content: <Meetings />,
        },
    ];
    const onTabChange = (key: string) => {
        dispatch(setSelectedTabKey(key));
    };

    return (
        <div
            className="meetings-container"
            id="home-tabpanel-meetings"
            role="tabpanel"
            aria-labelledby="home-tab-meetings"
        >
            <div className="meetings-container__left">
                <RefreshIcon />
                <Tabs
                    className="meetings-container__left-tabs"
                    activeKey={selectedTabKey}
                    onChange={onTabChange}
                    autoFocusWhenActive={false}
                >
                    {tabPanes
                        .filter((_) => !!_)
                        .map(({ key, text, content }) => {
                            return (
                                <Tabs.TabPane key={key} className="" tab={<TabBarNode text={text} />}>
                                    {content}
                                </Tabs.TabPane>
                            );
                        })}
                </Tabs>
            </div>
            <div className="meetings-container__v-divider"></div>
            <div className="meetings-container__right">
                <MeetingDetail />
            </div>
        </div>
    );
}
