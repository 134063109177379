import React from 'react';
import { CalendarEvent, MeetingItem } from '../Meetings/types';
import { RoutePath } from '../../../routes/routePath';
import { setSelectedMeetingItemId } from '../Meetings/redux/meetings-store';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../store/store-hooks';
import { View_Today_Upcoming_Meetings_Text } from '../LanguageResource';
import { isTeslaMode } from '../../../utils';

import { AddCalendarInUpComingFooter, AddCalendarAsUpComingFooter } from '../Meetings/CalendarIntegration/AddCalendar';
interface IProps {
    ongoings: Array<MeetingItem | CalendarEvent>;
    availableMeetings: Array<MeetingItem | CalendarEvent>;
    nearestUpcomings: Array<MeetingItem | CalendarEvent>;
    showAddCalendar: boolean;
}

export default function UpComingFooter(props: IProps) {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const isTesla = isTeslaMode();

    // tesla needs empty footer;
    if (isTesla) {
        return <div className="upcoming__footer"></div>;
    }

    const { ongoings, availableMeetings, nearestUpcomings, showAddCalendar } = props;

    // empty meeting list, show 'no upcoming meetings today' in parent.
    if (ongoings.length === 0 && nearestUpcomings.length === 0) {
        return null;
    }

    let restNum = 0;
    if (ongoings.length !== 0) {
        restNum = availableMeetings.length - ongoings.length;
    } else {
        restNum = availableMeetings.length - nearestUpcomings.length;
    }

    const onClick = () => {
        let id = '';
        if (ongoings.length !== 0) {
            id = ongoings[0].id;
        } else if (nearestUpcomings.length !== 0) {
            id = nearestUpcomings[0].id;
        }
        if (id) {
            dispatch(setSelectedMeetingItemId(id));
        }
        history.push(RoutePath.Meetings);
    };

    let content = null;

    if (restNum < 1 && showAddCalendar) {
        content = <AddCalendarAsUpComingFooter />;
    }

    if (restNum >= 1) {
        content = (
            <>
                <span>
                    {View_Today_Upcoming_Meetings_Text} ({restNum})
                </span>
                {showAddCalendar ? <AddCalendarInUpComingFooter /> : null}
            </>
        );
    }

    return (
        <>
            {content ? <div className="upcoming__divider"></div> : null}
            <div className="upcoming__footer" onClick={onClick}>
                {content}
            </div>
        </>
    );
}
