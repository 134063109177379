import React from 'react';
import { useAppSelector } from '../../../../store/store-hooks';
import { selectedMeetingItemSelector } from '../redux/meetings-selector';
import MeetingDetail from './MeetingDetail';
import Loading from '../../Loading';
import { FetchStatus } from '../../../../utils/constants';
import EmptyDetail from './EmptyDetail';

export default function ItemDetail() {
    const { loadingStatus } = useAppSelector((state) => state.meetings);
    const selectedMeetingItem = useAppSelector(selectedMeetingItemSelector);
    if (loadingStatus === FetchStatus.loading) {
        return <Loading />;
    }

    if (!selectedMeetingItem) {
        return <EmptyDetail />;
    }

    return <MeetingDetail selectedMeetingItem={selectedMeetingItem} key={selectedMeetingItem.id} />;
}
