import createWebim, {
    WebimStatus,
    WebimEventType,
    WebimPresenceType,
    WebimInvitation,
    WebimPropsConfig,
    WebimServerGroup,
    WebimDeviceEvent,
} from '@zoom/pwa-webim';

import mitt, { Emitter } from 'mitt';
import { setXmppStatusThunk } from '../../store/xmpp/xmpp-store';
import { enableMeetingTransferSelector } from '../../store/common/userWebSettingsSelector';
import {
    setGroups,
    setThisGroupContacts,
    createContactFromXmppContact,
    createGroupFromXmppGroup,
    handleInviteContactMessageThunk,
    handleInviteContactSyncMessageThunk,
    fetchContactsProfileThunk,
} from '../../features/Contacts/redux';
import { setBlockedList, setRecentChat } from '../../features/Chat/redux';
import { invitationManager, broadcastChannelAgent, meetingAgent, featureOptionManager } from '../../app-init';
import { BroadcastChannelMessageType, BroadcastMessageSource } from '../../utils/BroadcastChannel/types';
import { signoutNeedApiThunk } from '../../store/common/sign-out-thunk';
import { isTeslaMode, canTransferMeeting } from '../../utils/index';
import { setDeviceList } from '../../store/common/common-store';
import { Meeting_Transfer_From_Notify_Msg } from '../../features/Meetings/LanguageResource';
import { setUnreadCount } from '../../features/Chat/redux/chat-store';
import { AppStore } from '../../store';
import { IContactProfileParams, IGroup } from '../../features/Contacts/types';
import eventBus from '../../events/eventBus';
import { isDuringMeetingSelector } from '../../features/Meeting/redux';
import { waitXMPPConnectionReadyDecorator } from '../decorators/index';
import { getSsid } from '../../logger/utils';
import serviceContainer from '../container';
import { TransferLcp } from '../../features/Meeting/MeetingAgent';
import { defaultXmppConfig } from './xmpp-config';
import { getMeetingTypeEnum } from './utils';

export const XmppAgentEvents = {
    CONSTRUCTED: 0,
    DESTROYED: 1,
    CONNECTED: 2,
    DISCONNECTED: 3,
    SERVER_GROUPS: 4,
};

interface ICtorProps {
    store: AppStore;
}

export default class XmppAgent {
    constructor({ store }: ICtorProps) {
        this.store = store;
        this.emitter = mitt();
    }

    store: AppStore;
    webimInstance: ReturnType<typeof createWebim>;

    options: WebimPropsConfig = null;

    tickTimer: any = {
        timer: null,
    };

    beforeDndPresence = WebimPresenceType.available;

    currentPresence: WebimPresenceType = null;

    emitter: Emitter = null;

    externalGroup: Array<any> = [];

    lcpTimer: ReturnType<typeof setTimeout> = null;

    lcpResolve: (d: any) => void = null;

    init(serverConfig: any) {
        this.options = {
            ...defaultXmppConfig,
            ...serverConfig,
        };
        this.webimInstance = this.createWebimInstance(this.options);
        this.webimInstance.on(WebimEventType.ConnectionStatusChange, this.onConnectionStatusChange);
        this.webimInstance.on(WebimEventType.MessageChange, this.onXmppMessage);
        this.webimInstance.on(WebimEventType.GroupChange, this.onGroupChange);
        // this.webimInstance.on(WebimEventType.VcardChange, this.onVcardChange);
        this.webimInstance.on(WebimEventType.RecentChat, this.onRecentChat);
        this.webimInstance.on(WebimEventType.ServerGroups, this.onGetServerGroups);
        this.webimInstance.on(WebimEventType.RosterBlocklist, this.onGetBlocklist);
        this.webimInstance.on(WebimEventType.InviteContactChange, this.onInviteContactChange);
        this.webimInstance.on(WebimEventType.InviteContactSyncMessage, this.onInviteContactSyncMessageChange);

        this.webimInstance.on(WebimEventType.DeviceListChange, this.onDeviceListChange);
        this.webimInstance.on(WebimEventType.LoginOtherDevice, this.onLoginOtherDevice);
        this.webimInstance.on(WebimEventType.UnreadChatMessageCount, this.onUnreadChatMessageCount);

        this.emitter.emit(XmppAgentEvents.CONSTRUCTED as unknown as string);

        broadcastChannelAgent.emitter.on('*', this.onReceiveBoardcastChannelAction);
        return this;
    }

    uninit() {
        this.webimInstance &&
            this.webimInstance.off(WebimEventType.ConnectionStatusChange, this.onConnectionStatusChange);
        this.webimInstance && this.webimInstance.off(WebimEventType.MessageChange, this.onXmppMessage);
        this.webimInstance && this.webimInstance.off(WebimEventType.GroupChange, this.onGroupChange);
        // this.webimInstance && this.webimInstance.off(WebimEventType.VcardChange, this.onVcardChange);
        this.webimInstance && this.webimInstance.off(WebimEventType.RecentChat, this.onRecentChat);
        this.webimInstance && this.webimInstance.off(WebimEventType.RosterBlocklist, this.onGetBlocklist);
        this.webimInstance && this.webimInstance.off(WebimEventType.InviteContactChange, this.onInviteContactChange);
        this.webimInstance &&
            this.webimInstance.off(WebimEventType.InviteContactSyncMessage, this.onInviteContactSyncMessageChange);

        this.webimInstance && this.webimInstance.off(WebimEventType.DeviceListChange, this.onDeviceListChange);
        this.webimInstance && this.webimInstance.off(WebimEventType.LoginOtherDevice, this.onLoginOtherDevice);
        this.disconnect();
        this.emitter.emit(XmppAgentEvents.DESTROYED as unknown as string);
        this.emitter.all.clear();
        this.webimInstance = null;
    }

    isInitiated() {
        return !!this.webimInstance;
    }

    isConnected() {
        return !!(this.webimInstance && this.webimInstance.getCurrentStatus() === WebimStatus.CONNECTED);
    }

    createWebimInstance(serverConfig: WebimPropsConfig) {
        if (this.webimInstance) {
            return this.webimInstance;
        }
        return createWebim({ ...serverConfig });
    }

    connect(options: { jid: string; xmppToken: string }) {
        const { jid, xmppToken } = options;

        this.options = { ...this.options, ...options };
        if (!this.webimInstance) {
            this.webimInstance = this.createWebimInstance(this.options);
        }
        if (this.webimInstance.getCurrentStatus() !== WebimStatus.CONNECTED) {
            console.log('[X] - C');
            this.webimInstance.connect({ jid, xmppToken });
        }
    }

    disconnect() {
        try {
            this.webimInstance && this.webimInstance.disconnect();
        } catch (e) {
            console.error('disconnect x');
        }
    }

    clearDurationTimer() {
        clearTimeout(this.tickTimer.timer);
        this.tickTimer.timer = -1;
    }

    onConnectionStatusChange = (e: any) => {
        const {
            data: { status },
        } = e;
        console.log('onConnectionStatusChange status ==>', status);

        this.store.dispatch(setXmppStatusThunk(e));

        if (status === WebimStatus.CONNECTED) {
            this.getCurrentUserVcard();

            this.emitter.emit(XmppAgentEvents.CONNECTED as unknown as string, e);
            // dispatch xmmp ready
            eventBus.xmpp.xmppReady.dispatchEvent(status);
            this.webimInstance.fetchBlockList();
            this.webimInstance.queryDevices();
        }

        if (status === WebimStatus.DISCONNECTED) {
            this.emitter.emit(XmppAgentEvents.DISCONNECTED as unknown as string, e);
        }
    };

    getPureJid(fromJid: string, fromResource: string) {
        const resIndex = fromJid.lastIndexOf(fromResource);
        if (resIndex !== -1) return fromJid.substr(0, resIndex - 1);
        return fromJid;
    }

    getCurrentUserVcard() {
        const {
            common: { userInfo },
        } = this.store.getState();
        if (!userInfo?.jid) {
            return;
        }

        this.store.dispatch(fetchContactsProfileThunk([{ jid: userInfo.jid }], true));
    }

    onXmppMessage = (e: any) => {
        const invitation = e.data;
        if (invitation && invitation.action) {
            if (invitation.action === 'transfer_lcp' && invitation.lcp) {
                const {
                    common: {
                        userInfo: { jid },
                    },
                } = this.store.getState();
                const { fromJid, fromResource } = invitation;
                const pureFromJid = this.getPureJid(fromJid, fromResource);
                if (pureFromJid === jid) {
                    this.lcpTimer && clearTimeout(this.lcpTimer);
                    this.lcpResolve && this.lcpResolve(invitation.lcp);
                }
            } else if (invitation.action === 'request_lcp') {
                if (!this.isConnected()) return;
                const { fromDeviceId, fromResource, fromJid, toJid } = invitation;
                const { common, meeting } = this.store.getState();
                const {
                    userInfo: { displayName, jid },
                    deviceList,
                } = common;
                const {
                    meetingInfo: { meetingNo },
                } = meeting;
                const pureFromJid = this.getPureJid(fromJid, fromResource);
                if (pureFromJid === jid) {
                    meetingAgent
                        .requestLcpFromRWG()
                        .then((lcp) => {
                            const toRes = toJid.split('/')[1];
                            const deviceName = deviceList.find((device) => device.resource === toRes)?.name || '';
                            this.webimInstance.sendLcp(
                                meetingNo,
                                displayName,
                                fromResource,
                                fromDeviceId,
                                Meeting_Transfer_From_Notify_Msg(deviceName),
                                lcp,
                            );
                        })
                        .catch(() => {});
                }
            } else {
                if (!isTeslaMode()) invitationManager.onInvitationMessage(e);
            }
        }
    };

    onGetServerGroups = (e: any) => {
        this.emitter.emit(XmppAgentEvents.SERVER_GROUPS as unknown as string, e.data);
    };

    // e: group info from xmpp，include group members
    // send xmppAgent.getGroupMembers(groups) will trigger response
    onGroupChange = (e: any) => {
        console.log('xxxxxx', 'group change', e);
        const { data } = e;

        const waitingGroups: Array<Partial<IGroup>> = [];

        // remove Apps goroup, native have not display Apps group from 5.15.5
        data.filter((group: any) => group.name !== 'Apps').forEach((group: any) => {
            waitingGroups.push(createGroupFromXmppGroup(group));
            const contacts = group.items.map((item: any) => {
                return createContactFromXmppContact(item as any);
            });

            this.store.dispatch(
                setThisGroupContacts({
                    groupId: group.id,
                    contacts: contacts as any,
                }),
            );
        });

        this.store.dispatch(setGroups({ groups: waitingGroups } as any));
    };

    onGetBlocklist = (e: any) => {
        console.log('onGetBlocklist e ==>', e);

        const {
            chat: { blockedList },
        } = this.store.getState();

        const { data, type } = e.data;
        let result = blockedList;
        if (type === 'GET' || type === 'ADD') {
            result = Array.from(new Set([...blockedList, ...data]));
        } else if (type === 'REMOVE') {
            result = blockedList.filter((item) => !data.includes(item));
        }

        this.store.dispatch(setBlockedList(result));
    };

    /**
     *
     * @param {*} e
     * {
     *  data: [jid]
     * }
     */
    onRecentChat = (e: any) => {
        console.log('xmpp onRecentChat ==>', e);
        this.store.dispatch(setRecentChat(e.data));
    };

    @waitXMPPConnectionReadyDecorator()
    invite({
        mid,
        toJid,
        meetingNo,
        password = '',
        displayName = '', // disply name for you
        caption = '',
        iak = '',
        type,
        name,
        credential = '',
    }: any) {
        if (!this.isConnected()) return;

        if (!meetingNo) {
            console.error('Invite requires a meeting number');
            return;
        }
        if (!toJid) {
            console.error('Invite requires a valid jid');
        }
        const {
            common: { userInfo },
        } = this.store.getState();
        console.log('[I] - S');
        this.webimInstance.invite({
            jid: userInfo.jid,
            mid,
            meetingNo,
            password,
            displayName,
            caption,
            iak,
            type,
            name,
            credential,
            toJid: toJid,
        });
    }

    // when you have invited others
    // before others accept your invitation
    // you close the meeting.
    // send this message to notify the invitee
    @waitXMPPConnectionReadyDecorator()
    cancel({ meetingNo, mid, jid, toJid, name, type, toName = '' }: any) {
        if (!this.isConnected()) return;

        if (!meetingNo) {
            console.error('Cancel invite requires a meeting number');
            return;
        }
        if (!toJid) {
            console.error('Cancel invite requires a valid jid');
        }
        console.log('[I] - C');
        this.webimInstance.cancelInvitation({
            mid,
            meetingNo,
            jid, // current user's jid
            toJid, // who receive this cancel event
            name,
            type,
            toName,
        });
    }

    // others invite you
    @waitXMPPConnectionReadyDecorator()
    acceptInvitation(inviation: WebimInvitation) {
        if (!this.isConnected()) return;
        this.webimInstance.acceptInvitation(inviation);
    }

    @waitXMPPConnectionReadyDecorator()
    declineInvitation(invitation: WebimInvitation) {
        if (!this.isConnected()) return;
        this.webimInstance.declineInvitation(invitation);
    }

    private getMeetingInfoForPresenceChange() {
        const state = this.store.getState();
        const isDuringMeeting = isDuringMeetingSelector(state);
        const {
            meeting: { meetingInfo },
        } = state;

        if (!isDuringMeeting || !meetingInfo?.meetingNo) {
            return undefined;
        }

        const {
            meetingNo: number,
            caption: topic,
            isWebinar,
            isHost,
            needRegister,
            isSimuliveWebinar,
            mid,
        } = meetingInfo;

        const isEnableMeetingTransfer = enableMeetingTransferSelector(state);

        if (!canTransferMeeting(isEnableMeetingTransfer, isWebinar, isHost, needRegister, isSimuliveWebinar)) {
            return undefined;
        } else {
            return {
                number: String(number),
                topic,
                meetingId: mid,
                meetingType: getMeetingTypeEnum({ isWebinar }),
            };
        }
    }

    @waitXMPPConnectionReadyDecorator()
    queryPresence(jid: string) {
        if (!this.isConnected()) return null;
        // TODO, we should replace all xmppAgent.queryPresence with presenceManager.subscribe
        const presenceManager = serviceContainer.getPresenceManager();
        if (presenceManager) {
            return presenceManager.subscribePresence(jid);
        }
        return null;
    }

    @waitXMPPConnectionReadyDecorator()
    queryRecentChat() {
        if (!this.isConnected()) return;
        return this.webimInstance.queryRecentChat();
    }

    @waitXMPPConnectionReadyDecorator()
    getGroupMembers(groups: Array<WebimServerGroup>) {
        console.log('getGroupMembers groups ==>', groups);
        if (!this.isConnected()) return;

        return this.webimInstance.getGroupMembers(groups);
    }

    /**
     * get contact profile data form XMS server
     * */

    @waitXMPPConnectionReadyDecorator()
    getContactProfile(jids: Array<IContactProfileParams>, forceFetch = false) {
        const config = {
            jidParams: { jids },
            forceFetch,
        };

        return this.webimInstance.getContactProfile(config);
    }

    @waitXMPPConnectionReadyDecorator()
    unhideChannel(channelId: string) {
        return this.webimInstance?.unhideRoom(channelId) || Promise.reject('error');
    }

    /**
     * check if in channel，just support check self, form XMS server
     * */
    @waitXMPPConnectionReadyDecorator()
    checkMemberIsInChannel(channelIds: Array<string> = []) {
        const {
            common: {
                userInfo: { userId },
            },
        } = this.store.getState();

        const params = {
            sessions: channelIds,
            uid: userId,
        };

        // tesla meeting have not chat
        if (isTeslaMode()) {
            return Promise.reject([]);
        } else {
            return this.webimInstance.checkMemberIsInGroup(params);
        }
    }

    /**
     * Get recent Chat list for current login user form XMS server
     * @return Promise
     * */
    @waitXMPPConnectionReadyDecorator()
    getRecentChats() {
        return this.webimInstance.getRecentChats();
    }

    /**
     * receive broadcastChannelAgent emit message, and handle logic.
     * @param {string} messageType : broadcastChannelAgent messageType, same as e.data.type
     * */
    onReceiveBoardcastChannelAction = (_messageType: any, e: any) => {
        const {
            data: { from, type, payload },
        } = e;

        if (!from || from !== BroadcastMessageSource.PWA) return;

        switch (type) {
            case BroadcastChannelMessageType.XMPP_GET_CONNECT_STATUS:
                const {
                    common: { userInfo },
                } = this.store.getState();

                const alive = Boolean(userInfo?.jid);
                // post current tab xmpp-connect-status to other tab
                broadcastChannelAgent.postMessage({
                    from: BroadcastMessageSource.PWA,
                    type: BroadcastChannelMessageType.XMPP_COLLECT_CONNECTED_STATUS,
                    payload: {
                        alive,
                        ssid: getSsid(),
                    },
                });
                break;
            // other tab logs out, maybe xmpp token was revoked. so every tabs in the browser must follow
            case BroadcastChannelMessageType.SIGN_OUT:
                const { signOutType } = payload;
                this.store.dispatch(signoutNeedApiThunk(signOutType, false));
                break;
            default:
                break;
        }
    };

    setXmppErrorStatusByHand = (statusInfo: any) => {
        const xmppStatusData = {
            data: {
                status: WebimStatus.ERROR,
                statusInfo: statusInfo,
            },
        };
        this.store.dispatch(setXmppStatusThunk(xmppStatusData));
    };

    /**
     * invite contact
     * */
    @waitXMPPConnectionReadyDecorator()
    inviteContactByEmail(params = { inviterName: '', inviterEmail: '', inviteeEmail: '' }) {
        return this.webimInstance.inviteContactByEmail(params);
    }

    /**
     * delete contact
     * */
    @waitXMPPConnectionReadyDecorator()
    deleteContactByJid(jid = '') {
        return this.webimInstance.deleteContactByJid(jid);
    }

    @waitXMPPConnectionReadyDecorator()
    sendInviteContactSyncMessage(
        params = { action: '', inviteeJid: '', inviteeEmail: '', inviteeName: '', inviterName: '' },
    ) {
        this.webimInstance.sendInviteContactSyncMessage(params as any);
    }

    // if invite contact be accepted, will be triggered
    onInviteContactChange = (event: any) => {
        if (!featureOptionManager.isInviteContactOn()) return;
        this.store.dispatch(handleInviteContactMessageThunk(event, this.externalGroup));
    };

    onInviteContactSyncMessageChange = (event: any) => {
        if (!featureOptionManager.isInviteContactOn()) return;

        console.log('onInviteContactSyncMessageChange event ==>', event);
        this.store.dispatch(handleInviteContactSyncMessageThunk(event));
    };

    /**
     * get vcard by email from xms
     *
     * if  input email and current user is contact or same organization, will get the vcard data
     * */
    @waitXMPPConnectionReadyDecorator()
    checkAlreadyContact(email = '') {
        const params = {
            emails: [email],
        };

        return this.webimInstance.checkAlreadyContact(params);
    }

    /**
     * check if contact by jid
     * */
    @waitXMPPConnectionReadyDecorator()
    checkContactByJids(jids: Array<string> = []) {
        const params = {
            jids,
        };

        return this.webimInstance.checkContactByJids(params);
    }

    onDeviceListChange = (e: any) => {
        const currentDeviceId = this.webimInstance?.getStropheInstance()?.deviceId || '';
        const list = ((e as WebimDeviceEvent).data || []).map((item) => {
            return Object.assign({}, item, {
                isCurrentDevice: item.deviceId === currentDeviceId,
            });
        });

        this.store.dispatch(setDeviceList(list));
    };

    @waitXMPPConnectionReadyDecorator()
    changePresence(code: WebimPresenceType, isManual = false) {
        // Todo to remove
        if (!this.isConnected()) return;
        // what if we don't send presence from xmpp
        // how we sync transfer meeting info ???
        this.webimInstance.changePresence(code, isManual, this.getMeetingInfoForPresenceChange());
    }

    onLoginOtherDevice = () => {
        this.getMeetingInfoForPresenceChange();
    };

    @waitXMPPConnectionReadyDecorator()
    requestLcp(
        meetingNumber: string,
        dstdeviceid: string,
        caption: string,
        username: string,
        dstresource: string,
    ): Promise<TransferLcp> {
        if (!this.isConnected()) return Promise.reject('');
        this.webimInstance.requestLcp(meetingNumber, dstdeviceid, caption, username, dstresource);
        if (this.lcpTimer) clearTimeout(this.lcpTimer);
        return new Promise((resolve, reject) => {
            this.lcpResolve = resolve;
            this.lcpTimer = setTimeout(() => {
                reject('Timeout');
            }, 5000);
        });
    }

    onUnreadChatMessageCount = (result: any) => {
        console.log('onUnreadChatMessageCount data ==>', result);

        try {
            const {
                data: { reminder, acktime },
            } = result;

            const getSum = (data: any) => {
                let sum = 0;

                if (Array.isArray(data)) {
                    data.forEach((item) => {
                        const count = parseInt(item['@count']) || 0;
                        sum += count;
                    });
                } else {
                    const count = parseInt(data['@count']) || 0;
                    sum += count;
                }

                return sum;
            };

            let unreadCount = 0;
            if (reminder) {
                unreadCount += getSum(reminder);
            }

            if (acktime) {
                unreadCount += getSum(acktime);
            }

            console.log('onUnreadChatMessageCount unreadCount ==>', unreadCount);
            if (unreadCount > 0) {
                // just show red dot
                this.store.dispatch(setUnreadCount({ total: unreadCount, shown: 0 }));
            }
        } catch (error) {
            console.error('onUnreadChatMessageCount error ==>', error);
        }
    };
}
