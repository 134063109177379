import { WebimStatus } from '@zoom/pwa-webim';
import { invitationManager } from '../../../app-init';
import store from '../../../store';

interface inviteContactsParams {
    jid: string;
    name: string;
    type?: string;
}
interface inviteChatContactsParams extends inviteContactsParams {
    type: 'chat' | 'groupchat';
    withVideo: boolean;
}

/**
 * invite contact to meeting by 'Chat' or 'Contact'
 */
export const inviteContact = (contacts: Array<inviteContactsParams> | Array<inviteChatContactsParams>) => {
    const rootState = store.getState();
    const canWeInviteContacts = WebimStatus.CONNECTED === rootState.xmpp.status;
    const realContacts = contacts.filter((contact) => contact.jid);

    if (!canWeInviteContacts || realContacts.length === 0) {
        console.log('[I] - F');
        return false;
    }

    console.log('inviteContact realContacts ==>', realContacts);
    // Chat invite contact meeting
    if (realContacts[0]['type']) {
        console.log('inviteContact chat');
        const withVideo = (realContacts as Array<inviteChatContactsParams>)[0]?.withVideo;
        invitationManager.inviteContacts(realContacts, undefined, withVideo);
    } else {
        console.log('inviteContact contact');
        invitationManager.inviteContacts(realContacts, undefined, undefined, true);
    }
    return true;
};
