/* eslint-disable react/prop-types,react/display-name */
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import clsx from 'clsx';
import SmartCaptcha from './SmartCaptcha';
import { MediaType, CaptchaTypes } from './type';
import './index.scss';

const grecaptchaLoadedCallbackKey = 'grecaptchaLoadedCallback';
const VisbleEleId = 'VisibleReCaptchaEle';
let widgetId: undefined;

const VisibleReCaptcha = React.forwardRef(({ siteKey }: any, ref) => {
    useImperativeHandle(ref, () => ({
        getToken() {
            return window?.grecaptcha?.enterprise?.getResponse(widgetId);
        },
    }));

    useEffect(() => {
        if (!widgetId) {
            widgetId = window?.grecaptcha?.enterprise?.render(VisbleEleId, {
                sitekey: siteKey,
            });
        }
    }, [siteKey]);

    return <div id="VisibleReCaptchaEle"></div>;
});

/*
recaptcha__en.js, not recaptcha/enterprise.js will load 2 times for document and document inner captcha iframe.
This is by design.
https://stackoverflow.com/questions/41617645/google-recaptcha-javascript-loading-multiple-times#:~:text=This%20is%20intended%20behaviour%2C%20apparently.
*/
let recaptchaScriptInserted = false;
const insertRecaptchaScriptManually = (
    invisibleSiteKey: string,
    iframeNonce: string,
    isHcaptcha: boolean,
    isVisible: boolean,
) => {
    if (recaptchaScriptInserted) {
        return;
    }
    let recaptchaSource = 'https://www.recaptcha.net/recaptcha/enterprise.js';
    // let recaptchaSource = 'https://www.google.com/recaptcha/enterprise.js'; // back up domain
    if (isHcaptcha) {
        // Hcaptcha Doc: https://developers.google.com/recaptcha/docs/loading
        recaptchaSource = 'https://hcaptcha.com/1/api.js';
    }

    const scriptLists = [].slice.call(document.getElementsByTagName('script')) || [];

    if (scriptLists.every((o) => (o.src ?? '').indexOf('recaptcha/enterprise.js') === -1)) {
        const script = document.createElement('script');
        if (iframeNonce) {
            script.setAttribute('nonce', iframeNonce);
        }
        let src = `${recaptchaSource}?render=${invisibleSiteKey}`;
        if (isVisible) {
            src = `${recaptchaSource}?onload=${grecaptchaLoadedCallbackKey}&render=explicit`;
        }
        script.setAttribute('src', src);
        document.head.append(script);
        recaptchaScriptInserted = true;
    }
};

export const RecaptchaTOS = ({ className }: any) => {
    return (
        <div className={clsx('recaptcha_tos', className)}>
            Zoom is protected by reCAPTCHA and their&nbsp;
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer noopener">
                Privacy Policy
            </a>
            &nbsp;and&nbsp;
            <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer noopener">
                Terms of Service
            </a>
            &nbsp;apply.
        </div>
    );
};

const CaptchaContainer = React.forwardRef(
    (
        {
            className,
            captchaType,
            iframeNonce,
            invisibleSiteKey,
            onSmartCaptchaInputChange,
        }: {
            className: any;
            captchaType: any;
            iframeNonce?: string;
            invisibleSiteKey: string;
            onSmartCaptchaInputChange: (params: any) => void;
        },
        ref,
    ) => {
        const [smartCaptchaInfo, setSmartCaptchaInfo] = useState({
            mediaType: MediaType.Image,
            code: '',
        });
        const smartCaptchaRef = useRef<any>();
        const VisibleReCaptchaRef = useRef<any>();

        const isHCaptcha = captchaType === CaptchaTypes.HCaptcha;
        const isVisibleReCaptcha = captchaType === CaptchaTypes.VisibleRecaptcha;
        const isGoogleOrHCaptcha = captchaType === CaptchaTypes.Recaptcha || isHCaptcha;

        useEffect(() => {
            if (!recaptchaScriptInserted && (isGoogleOrHCaptcha || isVisibleReCaptcha)) {
                insertRecaptchaScriptManually(invisibleSiteKey, iframeNonce, isHCaptcha, isVisibleReCaptcha);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [captchaType]);

        const [recaptchaLoaded, setLoad] = useState(false);
        useEffect(() => {
            if (typeof window !== 'undefined') {
                (window as any)[grecaptchaLoadedCallbackKey] = () => {
                    setLoad(true);
                };
            }
        }, []);

        const restCaptcha = () => {
            const grecaptcha = isHCaptcha ? window.grecaptcha : window.grecaptcha?.enterprise;
            if (isVisibleReCaptcha) {
                grecaptcha?.reset(widgetId);
            } else {
                grecaptcha?.reset();
            }
        };

        useImperativeHandle(ref, () => ({
            getCaptchaCode: (cb: any) => {
                if (isGoogleOrHCaptcha) {
                    getTokenFromRecaptcha(cb);
                } else if (captchaType === CaptchaTypes.SmartCaptcha) {
                    cb({ ...smartCaptchaInfo, success: true });
                } else if (isVisibleReCaptcha) {
                    cb({
                        code: VisibleReCaptchaRef.current?.getToken(),
                    });
                }
            },
            refreshSmartCaptcha: () => {
                // used for smart captcha
                if (captchaType === CaptchaTypes.SmartCaptcha) {
                    smartCaptchaRef?.current?.refresh();
                }
            },
            restCaptcha: restCaptcha,
        }));

        const excuteCaptcha = async (grecaptcha: any, onGetTokenInfo: (...params: any) => void) => {
            let token = '';
            try {
                token = await grecaptcha.execute(invisibleSiteKey);
                return token
                    ? onGetTokenInfo({
                          success: true,
                          code: token,
                          mediaType: MediaType.Invisible,
                      })
                    : onGetTokenInfo({
                          success: false,
                          code: 'captcha token falsy',
                          mediaType: MediaType.Invisible,
                      });
            } catch (err) {
                // eslint-disable-next-line no-console
                return onGetTokenInfo({
                    success: false,
                    code: 'captcha error',
                    mediaType: MediaType.Invisible,
                });
            }
        };

        const getTokenFromRecaptcha = (onGetTokenInfo: any) => {
            const grecaptcha = isHCaptcha ? window.grecaptcha : window.grecaptcha?.enterprise;
            if (isHCaptcha) {
                excuteCaptcha(grecaptcha, onGetTokenInfo);
            } else {
                // TODO After backend support send Visible type
                // grecaptcha.render('grecaptchaContainer', {
                //   sitekey: invisibleSiteKey,
                // });
                if (grecaptcha?.ready) {
                    let grecaptchaReady = false;
                    grecaptcha.ready(async () => {
                        grecaptchaReady = true;
                        excuteCaptcha(grecaptcha, onGetTokenInfo);
                    });
                    setTimeout(() => {
                        // if after 5 seconds, grecaptcha is not ready,
                        // it may suggests recaptcha_en.js load failed
                        // we still excuteCaptcha(), code: 'captcha error' will be return,
                        if (!grecaptchaReady) {
                            excuteCaptcha(grecaptcha, onGetTokenInfo);
                        }
                    }, 5000);
                } else {
                    // if script from google (recaptcha/enterprise.js) load failed,
                    // we still excuteCaptcha(), code: 'captcha error' will be return,
                    // make sure frontend logic continue, eg: feedback click "Great" btn.
                    excuteCaptcha(grecaptcha, onGetTokenInfo);
                }
            }
        };

        const onInputChange = (mediaType: any, code: any) => {
            setSmartCaptchaInfo({ mediaType, code });
            onSmartCaptchaInputChange?.(code);
        };

        return (
            <div className={clsx('CaptchaContainer', className)}>
                {isVisibleReCaptcha && recaptchaLoaded && (
                    <VisibleReCaptcha ref={VisibleReCaptchaRef} siteKey={invisibleSiteKey} />
                )}
                {isGoogleOrHCaptcha && <RecaptchaTOS />}
                {captchaType === CaptchaTypes.SmartCaptcha && (
                    <SmartCaptcha onInputChange={onInputChange} ref={smartCaptchaRef} />
                )}
            </div>
        );
    },
);

export default CaptchaContainer;
