import { useEffect, useRef, useState } from 'react';
import { calendarAgent } from './CalendarAgent';
import { useAppSelector } from '../Phone/types';
import { useRouteMatch } from 'react-router-dom';
import { RoutePath } from '../../routes';
import { calendarMountedSingal } from './Calendar';
import { meetingAgent } from '../Meeting/init-meeting-agent';
import { useDispatch } from 'react-redux';
import { setShowCalendarSchedule } from './redux';
import { isCalendarEnabled } from '../../utils/featureOptions';

export const useScheduleDialogRouter = () => {
    const showCalendarSchedule = useAppSelector((state) => state.calendar.showCalendarSchedule);
    const matchCalendarRoute = useRouteMatch(RoutePath.Calendar);
    const matchHomeRoute = useRouteMatch(RoutePath.Home);
    const isMatch = Boolean(matchCalendarRoute) || Boolean(matchHomeRoute);

    // if current need to show calendar but the route is not matched, then hide the calendar.
    useEffect(() => {
        if (!isMatch) {
            calendarAgent.hideCalendarScheduleDialog();
        }
    }, [showCalendarSchedule, isMatch]);
};

export const useScheduleDialogLoading = () => {
    const [isScheduleDialogLoading, setIsScheduleDialogLoading] = useState(false);
    const showCalendarSchedule = useAppSelector((state) => state.calendar.showCalendarSchedule);
    const dispatch = useDispatch();
    const openScheduleDialogRef = useRef(null);

    const clickScheduleDialogHandler = () => {
        if (isCalendarEnabled()) {
            calendarMountedSingal.then(() => {
                calendarAgent.showCalendarScheduleDialog();
            });
            setIsScheduleDialogLoading(true);
            openScheduleDialogRef.current = setTimeout(() => {
                setIsScheduleDialogLoading(false);
                meetingAgent.openSchedulePage();
                calendarAgent.hideCalendarScheduleDialog();
                dispatch(setShowCalendarSchedule(false));
            }, 10000);
        } else {
            meetingAgent.openSchedulePage();
        }
    };

    useEffect(() => {
        // if calendar schedule dialog shows, then clear the timer
        if (showCalendarSchedule) {
            setIsScheduleDialogLoading(false);
            clearTimeout(openScheduleDialogRef.current);
        }
    }, [showCalendarSchedule]);

    useEffect(() => {
        // if home switch to other tab, the timer will be cleared.
        return () => {
            setIsScheduleDialogLoading(false);
            clearTimeout(openScheduleDialogRef.current);
        };
    }, []);

    return [clickScheduleDialogHandler, isScheduleDialogLoading];
};
