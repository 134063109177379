import React from 'react';
import './footer-link.scss';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import { setModal } from '../../store/modal/modal-store';
import { ABOUT } from '../../resource';
import Language from '../../features/Language/Language';

export default function FootLink() {
    const dispatch = useAppDispatch();
    const { language } = useAppSelector((state) => state.common);
    const showAbout = () => {
        dispatch(setModal({ name: 'about', data: {} }));
    };

    return (
        <div className="index__footer">
            <button className="index__footer-about" onClick={showAbout}>
                {ABOUT}
            </button>
            {language ? (
                <>
                    <div className="index__footer-divider"></div>
                    <Language />
                </>
            ) : null}
        </div>
    );
}
