import React, { useState } from 'react';
import Button from '../../../components/Button/Button';
import {
    Meeting_Transfer_In_Progress_Text,
    Meeting_Transfer_Switch_Text,
    On_Device_Text,
    Meeting_Transfer_Notify_Msg,
    Meeting_Transfer_Failed_Title,
    Meeting_Transfer_Failed_Desc,
    Meeting_Transfer_Error_Confirm,
} from '../LanguageResource';
import { IconCellphoneFill, IconDesktopFill } from '@zoom/icons-react';
import { xmppAgent, meetingAgent } from '../../../app-init';
import { setMeetingLcp, ITransferMeeting } from '../../Meeting/redux/meeting-store';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { IDevice } from '@zoom/pwa-webim';
import { toUint8Array } from 'js-base64';
import pako from 'pako';
import protobufjs from 'protobufjs/light';
import meetDescription from './meeting.proto.js';
import { cookies } from '../../../utils/Cookies/cookie';
import { CookieSetOptions } from 'universal-cookie';
import { isDuringMeetingSelector, setIsStartDisabled } from '../../Meeting/redux';
import { Modal } from 'Modal';
import { isTeslaMode } from '../../../utils';
import { WC_COOKIE_KEY } from '../../../utils/constants';

import './InMeetingItem.scss';
import { isZCCInVideoCall } from '../../../store/appModules/contact-center';

interface IProps {
    inMeetingDeviceList: Array<IDevice>;
}

export default function InMeetingItem(props: IProps): JSX.Element {
    const [visible, setVisible] = useState<boolean>(false);
    const { userInfo, isDuringMeeting, isStartDisabled } = useAppSelector((state) => {
        return {
            userInfo: state.common.userInfo,
            isDuringMeeting: isDuringMeetingSelector(state),
            isStartDisabled: state.meeting.isStartDisabled,
        };
    });

    const isZCCInMeeting = useAppSelector(isZCCInVideoCall);

    const { inMeetingDeviceList } = props;

    const { displayName: userName, userId } = userInfo;
    const isTesla = isTeslaMode();
    const dispatch = useAppDispatch();

    if (inMeetingDeviceList.length === 0) return null;

    const inMeetingEle = inMeetingDeviceList.map((inMeetingDevice) => {
        const { meetingInfo, name, resource, deviceId } = inMeetingDevice;
        if (meetingInfo && meetingInfo.length > 0) {
            const { topic, number } = meetingInfo[0];
            return (
                <div className="trans__meeting-container" key={number + deviceId}>
                    <div className="trans__meeting-status">
                        {resource.indexOf('mobile') !== -1 ? (
                            <IconCellphoneFill className="trans__meeting-status-icon" />
                        ) : (
                            <IconDesktopFill className="trans__meeting-status-icon" />
                        )}
                        {Meeting_Transfer_In_Progress_Text}
                    </div>
                    <div className="trans__meeting-topic">
                        <span className="trans__meeting-topic-text">{topic}</span>
                        <Button
                            disabled={isDuringMeeting || isStartDisabled || isZCCInMeeting}
                            size={isTesla ? 40 : 24}
                            onClick={async () => {
                                try {
                                    dispatch(setIsStartDisabled(true));
                                    console.log('[PWA] [Transfer]', 'fetch LCP from Xmpp');
                                    const lcp = await xmppAgent.requestLcp(
                                        number,
                                        deviceId,
                                        Meeting_Transfer_Notify_Msg(name),
                                        userName,
                                        resource,
                                    );
                                    console.log('[PWA] [Transfer]', 'fetched LCP from Xmpp success', lcp);
                                    const transferData: ITransferMeeting = {
                                        lcp,
                                    };
                                    const result = pako.inflate(toUint8Array(lcp));
                                    const root = protobufjs.Root.fromJSON(meetDescription);
                                    const launchConfDecoder = root.lookupType('MeetingProtos.LaunchConfParameter');
                                    const launchConf: any = launchConfDecoder.decode(result);
                                    const now = new Date();
                                    const duration = 1000 * 60 * 60 * 2; // 2 hours
                                    const cookieOptions: CookieSetOptions = {
                                        expires: new Date(now.getTime() + duration),
                                        path: '/',
                                        sameSite: 'none',
                                        secure: true,
                                    };
                                    cookies.set(WC_COOKIE_KEY.NAME, launchConf['userName'] || '', cookieOptions);
                                    cookies.set(WC_COOKIE_KEY.EMAIL, launchConf['userEmail'] || '', cookieOptions);
                                    const {
                                        password = '',
                                        hostId = '',
                                        options = 0,
                                        alternativeHost = [],
                                    } = launchConf['meeting'];

                                    const meetingOption: any = {
                                        transfer: 1,
                                    };
                                    const boToken = launchConf['boToken'];
                                    const boID = launchConf['boID'];
                                    const boTitle = launchConf['boTitle'];
                                    if (boToken && boID) {
                                        transferData.boID = boID;
                                        transferData.boTitle = boTitle || '';
                                        transferData.boToken = boToken;
                                    }
                                    transferData.isAudioOn = (options & 0x0002) !== 0;
                                    transferData.isVideoOn = (options & 0x0004) !== 0;
                                    transferData.isWaitingRoomVideoOn = !!+launchConf['appRecords'].find(
                                        (record: any) => record.key === 'waiting.room.video.on',
                                    )?.value;
                                    transferData.isWaitingRoomAudioOn = !!+launchConf['appRecords'].find(
                                        (record: any) => record.key === 'waiting.room.audio.on',
                                    )?.value;
                                    transferData.isAudioDisconnect = (options & 0x0080) !== 0;
                                    dispatch(setMeetingLcp(transferData));

                                    const isHost = hostId === userId;
                                    const isAlternativeHost = (alternativeHost as Array<{ id: string }>).find(
                                        (alt) => alt.id === userId,
                                    );

                                    if (isHost || isAlternativeHost) {
                                        meetingAgent.startMeetingWithMeetingNumber(number, meetingOption);
                                    } else {
                                        meetingOption.pwd = password;
                                        meetingAgent.joinMeeting(number, meetingOption);
                                    }
                                } catch (e) {
                                    setVisible(true);
                                } finally {
                                    dispatch(setIsStartDisabled(false));
                                }
                            }}
                        >
                            {Meeting_Transfer_Switch_Text}
                        </Button>
                    </div>
                    <div className="trans__meeting-device">{On_Device_Text(name)}</div>
                </div>
            );
        }
        return null;
    });

    return (
        <>
            <Modal needShowAboveBase={true} isOpen={visible} centered={true} needBackdrop={false} isDraggable={true}>
                <div className="trans__meeting-error">
                    <h5 className="trans__meeting-error-title">{Meeting_Transfer_Failed_Title}</h5>
                    <p className="trans__meeting-error-desc">{Meeting_Transfer_Failed_Desc}</p>
                    <Button size={isTesla ? 40 : 24} onClick={() => setVisible(false)}>
                        {Meeting_Transfer_Error_Confirm}
                    </Button>
                </div>
            </Modal>
            {inMeetingEle}
        </>
    );
}
