import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Button from '../../../../components/Button/Button';
import MeetingDetailTime from '../MeetingDetailTime';
import { CalendarEvent, MeetingItem } from '../types';
import useMeetingItem from '../useMeetingItem';
import useMeetingItemState from '../useMeetingItemState';
import { Hide_Invitation_Text, Show_Invitation_Text, Private_Text } from '../../LanguageResource';
import { IconCopy, IconEdit, IconCloseFill, IconTeamChatFill } from '@zoom/icons-react';

interface IProp {
    selectedMeetingItem: MeetingItem | CalendarEvent;
}

export default function MeetingDetail({ selectedMeetingItem }: IProp) {
    const [showInvitation, setShowInvitation] = useState(false);
    const [invitation, setInvitation] = useState('');
    const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

    if (!selectedMeetingItem) {
        return null;
    }

    const meetingNumberProperties = useMeetingItem({ item: selectedMeetingItem });
    const {
        topicTxt,
        timeTxt,
        hostTxt,
        meetingNoTxt,
        canStart,
        startTxt,
        startHandler,
        canCopy,
        copyTxt,
        copyHandler,
        canJoin,
        joinTxt,
        joinHandler,
        canViewOnZoomEvent,
        viewOnZoomEventTxt,
        viewOnZoomEventHandler,
        canEdit,
        editTxt,
        editHandler,
        canDelete,
        deleteTxt,
        deleteHandler,
        canMsgChannel,
        msgChannelTxt,
        msgChannelHandler,
        isPrivateMeeting,
        canViewDetail,
    } = meetingNumberProperties;

    const { disableStart, disableEdit, disableJoin, disableDelete } = useMeetingItemState(meetingNumberProperties);

    const onClickInviteButton = () => {
        copyHandler().then((invitation) => {
            if (copy(invitation)) {
                setInvitation(invitation);
                setShowCopiedTooltip(true);
            }
        });
    };

    const onClickInviteLink = () => {
        if (showInvitation) {
            setShowInvitation(false);
        } else {
            setShowInvitation(true);
            (copyHandler() as Promise<any>).then((invitation) => {
                setInvitation(invitation);
            });
        }
    };

    return (
        <div className="meetings__detail">
            <div id="meetings-detail-info-aria-desc" className="meetings__detail-info">
                <div className="meetings__detail-topic">{topicTxt}</div>
                {isPrivateMeeting ? (
                    <span className="meetings__detail-private">
                        <span>{Private_Text}</span>
                        <i className="black-lock-icon"></i>
                    </span>
                ) : null}
                {timeTxt ? <MeetingDetailTime data={selectedMeetingItem} timeTxt={timeTxt} /> : null}
                {hostTxt ? <div className="meetings__detail-host">{hostTxt}</div> : null}
                {meetingNoTxt ? <div className="meetings__detail-number">{meetingNoTxt}</div> : null}
            </div>

            {!isPrivateMeeting || canViewDetail ? (
                <div className="meetings__detail-btns">
                    {canStart ? (
                        <Button
                            id="meetings-detail-btn-start-aria"
                            size={Button.Size32}
                            type={Button.Normal}
                            extraCls={'meetings__detail-btn-start'}
                            onClick={startHandler}
                            disabled={disableStart}
                            aria-labelledby={'meetings-detail-info-aria-desc meetings-detail-btn-start-aria'}
                        >
                            {startTxt}
                        </Button>
                    ) : null}

                    {canJoin ? (
                        <Button
                            id="meetings-detail-btn-join-aria"
                            size={Button.Size32}
                            type={Button.Normal}
                            extraCls={'meetings__detail-btn-join'}
                            onClick={joinHandler}
                            disabled={disableJoin}
                            aria-labelledby={'meetings-detail-info-aria-desc meetings-detail-btn-join-aria'}
                        >
                            {joinTxt}
                        </Button>
                    ) : null}

                    {canMsgChannel ? (
                        <Button
                            size={Button.Size32}
                            type={Button.Tertiary}
                            extraCls={'meetings__detail-btn-msg-channel'}
                            onClick={msgChannelHandler}
                            LeftIcon={<IconTeamChatFill className="meetings__detail-btn__icon-l" aria-hidden={true} />}
                        >
                            {msgChannelTxt}
                        </Button>
                    ) : null}

                    {canCopy ? (
                        <OverlayTrigger
                            show={showCopiedTooltip}
                            placement="top"
                            trigger={['hover', 'focus']}
                            popperConfig={{ modifiers: [{ name: 'offset', options: { offset: [0, 6] } }] }}
                            overlay={(props) => {
                                return (
                                    <Tooltip id={`meeting-tooltip-${selectedMeetingItem.id}`} {...props}>
                                        Copied!
                                    </Tooltip>
                                );
                            }}
                        >
                            <Button
                                size={Button.Size32}
                                type={Button.Tertiary}
                                extraCls={'meetings__detail-btn-copy'}
                                onClick={onClickInviteButton}
                                onMouseLeave={() => setShowCopiedTooltip(false)}
                                onBlur={() => setShowCopiedTooltip(false)}
                                LeftIcon={<IconCopy className="meetings__detail-btn__icon-l" aria-hidden={true} />}
                            >
                                {copyTxt}
                            </Button>
                        </OverlayTrigger>
                    ) : null}

                    {canViewOnZoomEvent ? (
                        <Button
                            size={Button.Size32}
                            type={Button.Secondary}
                            extraCls={'meetings__detail-btn-view-onzoom'}
                            onClick={viewOnZoomEventHandler}
                        >
                            {viewOnZoomEventTxt}
                        </Button>
                    ) : null}

                    {canEdit ? (
                        <Button
                            size={Button.Size32}
                            type={Button.Tertiary}
                            extraCls={'meetings__detail-btn-edit'}
                            onClick={editHandler}
                            disabled={disableEdit}
                            LeftIcon={<IconEdit className="meetings__detail-btn__icon-l" aria-hidden={true} />}
                        >
                            {editTxt}
                        </Button>
                    ) : null}

                    {canDelete ? (
                        <Button
                            id="meetings-detail-btn-delete-aria"
                            size={Button.Size32}
                            type={Button.Tertiary}
                            extraCls={'meetings__detail-btn-delete'}
                            onClick={deleteHandler}
                            disabled={disableDelete}
                            LeftIcon={<IconCloseFill className="meetings__detail-btn__icon-l" aria-hidden={true} />}
                            aria-labelledby={'meetings-detail-info-aria-desc meetings-detail-btn-delete-aria'}
                        >
                            {deleteTxt}
                        </Button>
                    ) : null}
                </div>
            ) : null}

            {(!isPrivateMeeting || canViewDetail) && canCopy ? (
                <div className="meetings__detail-invitation">
                    <button className="meetings__detail-invitation-link" onClick={onClickInviteLink}>
                        {showInvitation ? Hide_Invitation_Text : Show_Invitation_Text}
                    </button>
                    {showInvitation ? (
                        <div className="meetings__detail-invitation-content">
                            {invitation ? <pre>{invitation}</pre> : <i className="z-loading"></i>}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
