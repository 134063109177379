import React from 'react';
import './Index.scss';
import '../../assets/styles/main.scss';
import { meetingAgent } from '../../app-init';
import { JOIN_MEETING, SIGN_IN_BUTTON } from '../../resource';
import { Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import { RoutePath, history } from '../../routes';
import { setShowJoinMeeting } from '../../store/common/common-store';
import { isTeslaMode } from '../../utils';
import Logo from './Logo';
import LogoTesla from './LogoTesla';

interface IProps {
    footerLink?: React.ReactElement;
}

export default function Index({ footerLink = null }: IProps) {
    const {
        meeting: { showWebclient },
    } = useAppSelector((_) => _);
    const dispatch = useAppDispatch();

    const joinMeeting = () => {
        if (window.PwaConfig?.enableMeetingRoute) {
            history.push(RoutePath.Join);
        } else {
            dispatch(setShowJoinMeeting(true));
        }
    };

    const signIn = () => {
        meetingAgent.signIn();
    };

    return (
        <div className="index">
            <div className="index-logo">{isTeslaMode() ? <LogoTesla /> : <Logo />}</div>

            <div className="btns-index">
                <Button
                    className="button button-size-40 button-normal btn-index-join"
                    onClick={joinMeeting}
                    disabled={showWebclient}
                >
                    {JOIN_MEETING}
                </Button>

                <Button
                    variant="light"
                    className="button button-size-40 button-secondary btn-index-signin"
                    onClick={signIn}
                    disabled={showWebclient}
                >
                    {SIGN_IN_BUTTON}
                </Button>
            </div>
            <div className="index__footer">{footerLink}</div>
        </div>
    );
}
