import React from 'react';
import { IconChatSmallFill, IconVideoOnFill } from '@zoom/icons-react';
import Button from '../../../components/Button/Button';
import { MEET_BUTTON, INVITE_TO_MEETING, Chat_Text } from '../../../resource';
import { IContactDetailData } from '../types';
import { getContactDisplayName } from '../redux';
import { chatAgent } from '../../Chat';
import { inviteContact } from '../hooks/useInviteContact';
import { PhoneCallButton } from '../PhoneCallToContact';
import { useAppSelector } from '../../../store/store-hooks';
import { isZCCInVideoCall } from '../../../store/appModules/contact-center';

interface IProps {
    contactDetailData: IContactDetailData;
    isIMBlocked: boolean;
}

export default function ContactControl({ contactDetailData, isIMBlocked }: IProps) {
    const { currentContact, showWebclient, isDuringMeeting, isAddon, canShowChatBtn, isStartDisabled } =
        contactDetailData;

    const isJoinOnly = window.PwaConfig.isNoMeetingsLicenseTypeUser;
    const { jid } = currentContact;
    const displayName = getContactDisplayName(currentContact);

    const isZCCInMeeting = useAppSelector(isZCCInVideoCall);

    // jump to chat
    const onClickChat = () => {
        chatAgent.chatWithUserOrChannel({ id: jid });
    };

    // invite contact meeting
    const onClickInviteContact = () => {
        if (!jid) return;
        inviteContact([{ jid, name: displayName }]);
    };

    return (
        <div className="contact-detail__controls">
            {isJoinOnly || isAddon ? null : (
                <>
                    {canShowChatBtn ? (
                        <Button
                            size={Button.Size32}
                            type={Button.Dark}
                            onClick={onClickChat}
                            LeftIcon={<IconChatSmallFill aria-hidden={true} />}
                        >
                            {Chat_Text}
                        </Button>
                    ) : null}

                    {isIMBlocked ? null : (
                        <Button
                            size={Button.Size32}
                            type={Button.Dark}
                            onClick={onClickInviteContact}
                            disabled={(showWebclient && !isDuringMeeting) || isStartDisabled || isZCCInMeeting}
                            LeftIcon={<IconVideoOnFill aria-hidden={true} />}
                        >
                            {isDuringMeeting ? INVITE_TO_MEETING : MEET_BUTTON}
                        </Button>
                    )}

                    <PhoneCallButton contact={currentContact} isAddon={isAddon} isIMBlocked={isIMBlocked} />
                </>
            )}
        </div>
    );
}
