import React, { forwardRef } from 'react';
import classnames from 'classnames';
import './Button.scss';

enum ButtonSize {
    Size16 = '16',
    Size24 = '24',
    Size32 = '32',
    Size40 = '40',
    Size48 = '48',
}

enum ButtonType {
    Normal = 'normal',
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Quaternary = 'quaternary',
    Quinary = 'quinary',
}

interface IProps {
    text?: string;
    title?: string;
    size?: string | number;
    type?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
    className?: string;
    extraCls?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    tabIndex?: number;
    LeftIcon?: React.ReactNode;
    RightIcon?: React.ReactNode;
    id?: string;
    style?: React.CSSProperties;
    autoFocus?: boolean;
    variant?: string;
}

const component: React.FC<IProps> = forwardRef<HTMLButtonElement, IProps>(function Button(
    {
        text = '',
        size = ButtonSize.Size24,
        type = ButtonType.Normal,
        onClick = () => {
            /** noop */
        },
        extraCls = '',
        children,
        disabled = false,
        tabIndex = 0,
        LeftIcon = null,
        RightIcon = null,
        ...rest
    },
    ref,
) {
    const btnCls = classnames(
        'z-button',
        `z-button-size-${size}`,
        {
            [`z-button-type-${type}`]: type,
            disabled: disabled,
        },

        extraCls,
    );

    const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!disabled && onClick) {
            onClick(e);
        }
    };

    const _text = text || children || null;
    return (
        <button className={btnCls} disabled={disabled} onClick={onButtonClick} ref={ref} {...rest}>
            {LeftIcon ? LeftIcon : null}
            {_text ? <span>{_text}</span> : null}
            {RightIcon ? RightIcon : null}
        </button>
    );
});

const Button: typeof component & {
    Size16: string;
    Size24: string;
    Size32: string;
    Size40: string;
    Size48: string;
    Normal: string;
    Primary: string;
    Secondary: string;
    Tertiary: string;
    Quaternary: string;
    Quinary: string;
    CTA: string;
    Icon: string;
    Destructive: string;
    Text: string;
    Dark: string;
} = component as any;

// size
Button.Size16 = ButtonSize.Size16;
Button.Size24 = ButtonSize.Size24;
Button.Size32 = ButtonSize.Size32;
Button.Size40 = ButtonSize.Size40;
Button.Size48 = ButtonSize.Size48;

// type
Button.Normal = 'normal';
Button.Primary = 'primary';
Button.Secondary = 'secondary';
Button.Tertiary = 'tertiary';
Button.Quaternary = 'quaternary';
Button.Quinary = 'quinary';
Button.CTA = 'cta'; // call to action
Button.Icon = 'icon';

Button.Destructive = 'destructive';
Button.Text = 'text';

// dark outline
Button.Dark = 'dark';

export default Button;
