import React from 'react';
import { IconLoadingSpikes } from '@zoom/icons-react';
import { useAppSelector } from '../../store';
import { LOADING_WHITEBOARD } from '../../resource';

export default function WhiteboardLoading() {
    const isWbLoaded = useAppSelector((state) => state.whiteboard.isWbLoaded);

    if (isWbLoaded) {
        return null;
    }

    return (
        <div className="pwa-wb__loading">
            <IconLoadingSpikes className="pwa-wb__loading__icon" />
            <p className="pwa-wb__loading__text">{LOADING_WHITEBOARD}</p>
        </div>
    );
}
