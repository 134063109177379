import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store-hooks';
import './index.scss';
import { refreshListThunk } from './Meetings/redux/meetings-thunk';
import { FetchStatus } from '../../utils/constants';
import { throttle } from 'lodash-es';
import { IconRefresh } from '@zoom/icons-react';

export default function RefreshIcon() {
    const dispatch = useAppDispatch();
    const { loadingStatus } = useAppSelector((state) => state.meetings);

    const refresh = throttle(() => {
        if (loadingStatus === FetchStatus.loading) {
            return;
        }
        dispatch(refreshListThunk());
    }, 3000);

    return (
        <button className="meetings-container__left-header__refresh" onClick={refresh}>
            <IconRefresh />
        </button>
    );
}
