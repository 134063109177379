import { getLaplace, getLaplaceLogPrecedingTags, toLogConfig } from './laplace';
import { TagType, mergeTags, stringify } from './utils';

export const log = (message: string | Object, aspectTags: TagType, tags?: TagType) => {
    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace.logWithoutCache(stringify(message), mergeTags(aspectTags, tags), toLogConfig);
    });
};

export const warn = (message: string | Object, aspectTags: TagType, tags: TagType) => {
    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace.logWithoutCache(stringify(message), mergeTags(aspectTags, tags), toLogConfig);
    });
};

export const info = (message: string | Object, aspectTags: TagType, tags: TagType) => {
    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace.logWithoutCache(stringify(message), mergeTags(aspectTags, tags), toLogConfig);
    });
};

const debug = (message: string | Object, aspectTags: TagType, tags: TagType) => {
    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace.logWithoutCache(stringify(message), mergeTags(aspectTags, tags), toLogConfig);
    });
};

const error = (message: string | Object, aspectTags: TagType, tags: TagType) => {
    return getLaplace().then((laplace) => {
        if (!laplace) {
            return null;
        }
        return laplace.logWithoutCache(stringify(message), mergeTags(aspectTags, tags), toLogConfig);
    });
};

const print = (message: string) => {
    console.log(message);
};

// method
const customPrint = (message: string, method: keyof Console) => {
    if (!method) {
        console.log(message);
    }

    try {
        (console[method] as Function)(message);
    } catch (error) {
        console.error(error);
    }
};

const makeLoggerWithoutCache = (tags: TagType) => {
    const constantTags = getLaplaceLogPrecedingTags();
    const aspectTags = mergeTags(constantTags, tags);

    return {
        log: (message: string | Object, tags?: TagType) => log(message, aspectTags, tags),
        warn: (message: string | Object, tags?: TagType) => warn(message, aspectTags, tags),
        info: (message: string | Object, tags?: TagType) => info(message, aspectTags, tags),
        debug: (message: string | Object, tags?: TagType) => debug(message, aspectTags, tags),
        error: (message: string | Object, tags?: TagType) => error(message, aspectTags, tags),
        print: (message: string) => print(message),
        customPrint: (method: keyof Console) => (message: string) => customPrint(message, method),
    };
};

export default makeLoggerWithoutCache;
