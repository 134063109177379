import { UAParser } from 'ua-parser-js';
import { replaceRoute } from '../../../routes';
import { BrowserDetect, isInStandaloneMode, openTab, reload } from '../../../utils';
import { getRouteFromURL } from '../../Meetings/Meetings/utils';

export const goToPostAttendeeUrl = (postAttendeeUrl: string) => {
    if (!postAttendeeUrl) {
        return;
    }
    if (isInStandaloneMode()) {
        openTab(postAttendeeUrl);
        return;
    }
    reload(postAttendeeUrl);
};

export const removeParams = (paramKey: string) => {
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    params.delete(paramKey);
    replaceRoute(getRouteFromURL(location.origin + location.pathname + '?' + params.toString()));
};

export const getGPUInfo = () => {
    try {
        let canvas = document.createElement('canvas');
        const glCtx = canvas.getContext('webgl');
        canvas = null;
        if (glCtx) {
            const debugInfo = glCtx.getExtension('WEBGL_debug_renderer_info');
            const renderer = glCtx.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            const vendor = glCtx.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            return `${vendor} ${renderer.toLowerCase()}`;
        }
        return '';
    } catch (e) {
        console.error(e);
        return '';
    }
};

export const supportTouch = (): boolean =>
    (window.PointerEvent && 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) ||
    (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches);

export function getUA() {
    return UAParser(window.navigator.userAgent);
}

export function isTablet() {
    if (BrowserDetect.isChromeOS()) {
        return false;
    }
    const userAgent = navigator.userAgent.toLowerCase();
    const tabletRegex = /ipad|android(?!.*mobile)|tablet/i;

    if (tabletRegex.test(userAgent) || getUA()?.device?.type === 'tablet') {
        return true;
    }

    const minTabletWidth = 600;
    const maxTabletWidth = 1280;

    const screenWidth = Math.min(window.screen.width, window.screen.height);

    const hasTouchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    return screenWidth >= minTabletWidth && screenWidth <= maxTabletWidth && hasTouchSupport;
}

export const isIPad = () => {
    const ua = navigator.userAgent.toLowerCase();
    let portraitWidth = 0;
    let portraitHeight = 0;

    // iPad height, resolution table width // same width Note that there is 1668px
    const screens: Record<number, number> = {
        1024: 768, // iPad mini 4, iPad Air 2, iPad Pro 9.7,
        1112: 834, // iPad Pro 10.5
        1194: 834, // iPad Pro 11
        1366: 1024, // iPad Pro 12.9 2nd
    };
    // held vertically held horizontally (portrait mode, landscape mode)
    // determines in portrait position either case an
    if (window.screen.width < window.screen.height) {
        portraitWidth = window.screen.width;
        portraitHeight = window.screen.height;
    } else {
        portraitWidth = window.screen.height;
        portraitHeight = window.screen.width;
    }

    // iOS and iPadOS return true if the iPad either case return
    return ua.indexOf('ipad') > -1 || (ua.indexOf('macintosh') > -1 && screens[portraitHeight] === portraitWidth);
};

export const isMobileDevice = () =>
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    isIPad() ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    isTablet();

export const getOSVersionInfo = (function () {
    let Sys = {
        os: '',
        osVersion: '',
        osType: 0,
        browser: {
            name: '',
            version: '',
        },
    };
    const getInfo = function () {
        try {
            let result = UAParser(navigator.userAgent);
            let bTablet = result?.device?.type == 'tablet' || isTablet();
            let bMobile = result?.device?.type == 'mobile' || isMobileDevice();
            let os = result?.os?.name;
            let version = result?.os?.version;

            let deviceType = 0; // 'Desktop';
            if (bTablet) {
                deviceType = 4; // 'Tablet';
            } else if (bMobile) {
                deviceType = 1; // 'Mobile';
            }
            Sys.osType = deviceType;
            Sys.os = os;
            Sys.osVersion = version;
            Sys.browser = Object.assign({}, result.browser);
            if (os?.toLowerCase().includes('mac') && (bTablet || bMobile)) {
                Sys.os = 'ios';
            }

            if (os?.toLowerCase().includes('linux') && (bTablet || bMobile)) {
                Sys.os = 'android';
            }

            let majorVersion = (version || '').split('.')[0];

            if (
                os &&
                // @ts-ignore
                navigator.userAgentData &&
                ((os.toLowerCase().includes('win') && majorVersion == '10') ||
                    os.toLowerCase().includes('android') ||
                    os.toLowerCase().includes('mac'))
            ) {
                // @ts-ignore
                navigator.userAgentData.getHighEntropyValues(['platform', 'platformVersion']).then((values) => {
                    os = values?.platform;
                    const platform = (os || '').toLowerCase();

                    const majorPlatformVersion = parseInt((values.platformVersion || '').split('.')[0]);

                    if (platform.includes('win')) {
                        if (majorPlatformVersion >= 13) {
                            version = '11';
                        } else if (majorPlatformVersion > 0) {
                            version = '10';
                        } else {
                            version = '7';
                        }

                        Sys.os = os;
                        Sys.osVersion = version;
                    } else if (platform.includes('android')) {
                        version = values.platformVersion;
                        Sys.osVersion = version;
                    } else if (platform.includes('mac')) {
                        version = values.platformVersion;
                        Sys.osVersion = version;
                    }
                });
            }
        } catch (e) {}
    };

    getInfo();

    return function () {
        return Sys;
    };
})();
