import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import { Dropdown } from 'react-bootstrap';
import {
    setSelectedMeetingHostId,
    meetingHostListSelector,
    maybeUpdateSelectedMeetingItemIdThunk,
} from './redux/meetings-store';
import './MeetingHostFilter.scss';
import { Hosted_By_Text } from '../LanguageResource';
import { FetchStatus } from '../../../utils/constants';

export default function MeetingHostFilter() {
    const dispatch = useAppDispatch();
    const hostList = useAppSelector(meetingHostListSelector);
    const { selectedMeetingHostId, loadingStatus } = useAppSelector((state) => state.meetings);
    const nameRef = useRef<HTMLSpanElement>(null);

    if (loadingStatus === FetchStatus.loading || loadingStatus === FetchStatus.idle) {
        return null;
    }

    // at least ['everyone', 'me']
    if (hostList.length < 3) return null;
    const selectedHost = hostList.find((_) => _.userId === selectedMeetingHostId);
    const popperConfig = {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: () => {
                        if (!nameRef.current) return [0, 10];
                        const offsetLeft = nameRef.current.offsetLeft;
                        return [offsetLeft, 10];
                    },
                },
            },
        ],
    };
    return (
        <div className="meetings__host-filter">
            <div className="meetings__host-filter-content">
                <Dropdown
                    id="meetings__host-filter__dropdown"
                    onSelect={(eventKey, _event) => {
                        dispatch(setSelectedMeetingHostId(eventKey));
                        dispatch(maybeUpdateSelectedMeetingItemIdThunk());
                    }}
                >
                    <Dropdown.Toggle id="meetings__host-filter__toogle" as="button">
                        <span className="meetings__host-filter__prefix">{Hosted_By_Text}</span>
                        <span className="meetings__host-filter__name" ref={nameRef}>
                            {selectedHost.displayName}
                        </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu popperConfig={popperConfig}>
                        {hostList.map((item) => {
                            const { userId, displayName } = item;
                            const selected = userId === selectedMeetingHostId;
                            return (
                                <Dropdown.Item as="button" key={userId} eventKey={userId} active={selected}>
                                    <span>{displayName}</span>
                                    {selected ? <i className="checker"></i> : null}
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
}
