import React from 'react';
import { getLabelText, ILabelRender } from '../../redux';
import { A11Y_POLICY } from '../../a11y';

interface IProps {
    data: ILabelRender;
    style: React.CSSProperties;
    a11yIndex?: string;
    renderIndex?: number;
}

export default function Label({ data, style, a11yIndex, renderIndex }: IProps) {
    const { labelId } = data;
    const text = getLabelText(labelId);
    const getAriaLabelText = () => {
        return `${text}`;
    };
    return (
        <div
            className="contacts-label"
            style={style}
            tabIndex={-1}
            role="heading"
            aria-level={1}
            aria-label={getAriaLabelText()}
            data-a-l={a11yIndex}
            data-a-walk-policy={A11Y_POLICY.CONTACT_TREE_ITEM_POLICY}
            data-a-left-key-disabled={true}
            data-a-render-index={renderIndex}
        >
            {text}
        </div>
    );
}
