import { fetchUserInfo, signout, fetchUserWebSettings } from '../../services/http/apis';
import { OTP_NOTIFICATION_TEXT } from '../../resource';
import BrowserNotification, { NOTIFICATION_TAGS } from '../../utils/BrowserNotification';
import { meetingAgent } from '../../app-init';
import type { AppDispatch, AppGetState } from '../../store';
import { setUserInfo, setMediaConfig, updateUserSettings, setUserWebSettings, IUserInfo } from './common-store';
import { GetUserInfoError } from './error';
import easyStore from '../../utils/storage';
import { isTeslaMode } from '../../utils';
import { setModal } from '../modal/modal-store';
import { CheckLoginResult, checkUserLoginMethodWithChromePolicy } from '../../utils/chrome-policy/policy-checks';

export const getUserInfoThunk = () => async (dispatch: AppDispatch) => {
    try {
        // try to get user info
        const data = await fetchUserInfo();
        if (0 === data.errorCode) {
            return data.result;
        }
        if (201 === data.errorCode) {
            throw Error(GetUserInfoError.NotLogin);
        } else {
            throw Error(data.errorMessage);
        }
    } catch (e) {
        dispatch(setUserInfo(null));
        return Promise.reject(e);
    }
};

export const getUserWebSettingsThunk = () => async (dispatch: AppDispatch) => {
    try {
        // try to get user web settings
        const data = await fetchUserWebSettings();
        if (0 === data.errorCode) {
            dispatch(setUserWebSettings(data.result));
            return data.result;
        }
        if (201 === data.errorCode) {
            throw Error(GetUserInfoError.NotLogin);
        } else {
            throw Error(data.errorMessage);
        }
    } catch (e) {
        return Promise.reject(e);
    }
};

export const checkUserLoginMethodWithChromePolicyThunk = (userInfo: IUserInfo) => async (dispatch: AppDispatch) => {
    const checkPolicyResult = await checkUserLoginMethodWithChromePolicy(userInfo);
    if (CheckLoginResult.RejectAll === checkPolicyResult) {
        dispatch(signoutThunk());
        throw Error(GetUserInfoError.NotAllowSignByAdmin);
    } else if (CheckLoginResult.Reject === checkPolicyResult) {
        dispatch(signoutThunk()).then(() => {
            meetingAgent.signIn(); // load signin page
        });
        throw Error(GetUserInfoError.NotAllowSignByCurrentType);
    } else {
        return userInfo;
    }
};

export const setUserInfoThunk = (userInfo: IUserInfo | null) => (dispatch: AppDispatch) => {
    dispatch(setUserInfo(userInfo));
};

export const signoutThunk = () => (dispatch: AppDispatch) => {
    return signout()
        .catch(() => {
            console.error('sign out failed');
        })
        .finally(() => {
            dispatch(setUserInfoThunk(null));
        });
};

export const setMediaConfigThunk = (config: any) => (dispatch: AppDispatch, getState: AppGetState) => {
    const {
        common: { isPhoneModuleLoaded },
    } = getState();

    if (isPhoneModuleLoaded) {
        import(/* webpackChunkName: "phone" */ '../../features/Phone').then(
            ({ setSpeakerDeviceId, setMicrophoneDeviceId }) => {
                const { speakerDeviceId, microphoneDeviceId } = config;
                if ('speakerDeviceId' in config) {
                    dispatch(setSpeakerDeviceId(speakerDeviceId));
                }
                if ('microphoneDeviceId' in config) {
                    dispatch(setMicrophoneDeviceId(microphoneDeviceId));
                }
            },
        );
    }

    dispatch(setMediaConfig(config));
};

export const updateUserSettingsThunk = (settings: any) => (dispatch: AppDispatch) => {
    dispatch(updateUserSettings(settings));

    Object.entries(settings)
        .flatMap(([key, value]) => {
            return Object.entries(value).map(([innerKey, innerValue]) => {
                return [`${key}.${innerKey}`, innerValue];
            });
        })
        .forEach((pairs) => {
            easyStore.localStore.easySet(pairs[0], pairs[1]);
        });
};

export const showOTPNotificationThunk = (event: any) => (dispatch: AppDispatch) => {
    if (!isTeslaMode()) {
        dispatch(setModal({ name: 'accountTakeover', data: event }));

        if (!window.document.hidden) return;

        new BrowserNotification().notify({
            tag: NOTIFICATION_TAGS.ONE_TIME_PASSWORD,
            title: '',
            body: OTP_NOTIFICATION_TEXT,
            onClick: (notification) => {
                window.focus();
                notification.close();
            },
            onClose: () => {},
            renotify: false,
        });
    }
};
