import React, { useRef, useEffect } from 'react';
import { whiteboardAgent } from '../../app-init';
import { Whiteboards_Text } from '../../resource';
import './index.scss';
import { WBPostMessage_EVENT, WHITEBOARD_URL } from './types';

export default function Whiteboard() {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const postMessage = (event: WBPostMessage_EVENT) => {
            try {
                const contentWindow = iframeRef?.current?.contentWindow;
                if (contentWindow) {
                    contentWindow.postMessage(event);
                }
            } catch (e) {
                console.error('wb error', e);
            }
        };

        whiteboardAgent.setPostMessage(postMessage);
        window.addEventListener('message', whiteboardAgent.onWhiteboardMessage);

        return () => {
            window.removeEventListener('message', whiteboardAgent.onWhiteboardMessage);
            whiteboardAgent.setPostMessage(null);
        };
    }, []);

    return (
        <div className="pwa-wb__content">
            <iframe className="pwa-wb__iframe" ref={iframeRef} src={WHITEBOARD_URL} title={Whiteboards_Text}></iframe>
        </div>
    );
}
