import React from 'react';

interface IProps {
    i18nString: string;
    stubs: Array<React.ReactNode>;
}
// i18nStrings 'hello {0} you, i am your {1}'
const prepareElements = (i18nString: string, stubs: Array<React.ReactNode>) => {
    const elements: Array<React.ReactNode> = [];
    const allMatches = i18nString.matchAll(/\{(\d+)\}/g);
    let lastIndex = 0;
    for (const match of allMatches) {
        const left = match.input.substring(lastIndex, match.index);
        const stubIndex = Number(match[1]);
        elements.push(left);
        // translated strings may be '***** {1} *** ^^^^ {0} ####'
        elements.push(stubs[stubIndex]);
        lastIndex = match.index + match[0].length;
    }

    elements.push(i18nString.slice(lastIndex));
    return elements;
};

export default function I18nStrings(props: IProps) {
    const { i18nString, stubs } = props;
    const elements = prepareElements(i18nString, stubs);
    return <>{elements.filter(Boolean).map((ele) => ele)}</>;
}
