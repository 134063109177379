import React, { useEffect } from 'react';
import {
    getCalendarListThunk,
    getMeetingListThunk,
    setCalculateUpcomingMeetingsAt,
    setSelectedMeetingItemId,
    upcomingMeetingsSelector,
} from '../Meetings/redux/meetings-store';
import './Upcoming.scss';
import './Upcoming-tesla.scss';
import { useAppDispatch, useAppSelector } from '../../../store/store-hooks';
import UpcomingHeader from './UpcomingHeader';
import UpComingFooter from './UpComingFooter';
import UpcomingDetail from './UpcomingDetail';
import UpcomingItem from './UpcomingItem';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../routes/routePath';
import { FetchStatus } from '../../../utils/constants';
import { isTeslaMode } from '../../../utils';
import UpcomingEmpty from './UpcomingEmpty';
import InMeetingItem from './InMeetingItem';
import useInMeetingDeviceList from './useInMeetingDeviceList';
import { enableMeetingTransferSelector } from '../../../store/common/userWebSettingsSelector';
import { ReAuthCalendarInUpcoming } from '../Meetings/CalendarIntegration/ReAuthCalendar';

export default function Upcoming() {
    const { availableMeetings, ongoings, nearestUpcomings } = useAppSelector(upcomingMeetingsSelector);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const isTesla = isTeslaMode();
    const { loadingStatus, isConnectedToCalendar } = useAppSelector((state) => state.meetings);
    const inMeetingDeviceList = useInMeetingDeviceList();
    const isEnableMeetingTransfer = useAppSelector(enableMeetingTransferSelector);
    useEffect(() => {
        if (loadingStatus === FetchStatus.idle || loadingStatus === FetchStatus.loading) {
            return;
        }
        dispatch(getMeetingListThunk());
        dispatch(getCalendarListThunk());
    }, []);

    useEffect(() => {
        const timer = window.setInterval(() => {
            dispatch(setCalculateUpcomingMeetingsAt(Date.now()));
        }, 45 * 1000); // 60s may have some delay, but smaller interval means more computations

        return () => {
            window.clearInterval(timer);
        };
    }, []);

    const toView = (id: string) => {
        dispatch(setSelectedMeetingItemId(id));
        if (!isTesla) {
            history.push(RoutePath.Meetings);
        }
    };
    let contentElem = null;
    let dataList: typeof ongoings = [];

    if (ongoings.length !== 0) {
        dataList = ongoings;
    } else if (nearestUpcomings.length !== 0) {
        dataList = nearestUpcomings;
    }

    if (dataList.length === 0 && (inMeetingDeviceList.length === 0 || !isEnableMeetingTransfer)) {
        contentElem = <UpcomingEmpty showAddCalendar={!isConnectedToCalendar} />;
    } else if (dataList.length === 1) {
        contentElem = <UpcomingDetail data={dataList[0]} toView={toView} />;
    } else {
        let Item = UpcomingItem;
        if (isTesla) {
            Item = UpcomingDetail;
        }
        contentElem = (
            <>
                {dataList.map((item) => (
                    <Item key={item.id} data={item} toView={toView} />
                ))}
            </>
        );
    }

    return (
        <div className="upcoming">
            <UpcomingHeader />
            <ReAuthCalendarInUpcoming />
            <div className="upcoming__content">
                {isEnableMeetingTransfer && inMeetingDeviceList.length > 0 ? (
                    <InMeetingItem inMeetingDeviceList={inMeetingDeviceList} />
                ) : null}
                {contentElem}
            </div>
            <UpComingFooter
                ongoings={ongoings}
                nearestUpcomings={nearestUpcomings}
                availableMeetings={availableMeetings}
                showAddCalendar={!isConnectedToCalendar}
            />
        </div>
    );
}
