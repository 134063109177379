import microApp from '@micro-zoe/micro-app';
import { CALENDAR_APP_NAME } from '../constant';
import eventBus from '../../../events/eventBus';
import store from '../../../store';
import { signoutWhenSeesionExpiredThunk } from '../../../store/common/sign-out-thunk';
import { userSessionLogger } from '../../../logger/pwa-loggers';
import serviceContainer from '../../../services/container';
import { setShowCalendarSchedule } from '../redux';
import { RoutePath, history } from '../../../routes';

type CalendarMessageType = {
    type: string;
    payload: any;
};

type MeetingParams = {
    meetingNumber: string;
    meetingPassword?: string;
    originalMeetingNumber: string;
};

export class CalendarAgent {
    private meetingEndEventId: string;
    constructor() {
        this.onMessage = this.onMessage.bind(this);
        this.meetingEndEventId = '0';
    }
    init() {
        microApp.addDataListener(CALENDAR_APP_NAME, this.onMessage);
        this.meetingEndEventId = eventBus.meeting.end.subscribe(({ meetingInfo }) => {
            microApp.setData(CALENDAR_APP_NAME, {
                type: 'meeting_ended',
                date: new Date(),
                payload: meetingInfo,
            });
        });
        const id = eventBus.app.willSingout.subscribe(() => {
            this.notifyToDestroy();
            eventBus.app.willSingout.unsubscribe(id);
        });
    }

    uninit() {
        microApp.removeDataListener(CALENDAR_APP_NAME, this.onMessage);
        eventBus.meeting.end.unsubscribe(this.meetingEndEventId);
    }

    onMessage(message: CalendarMessageType) {
        switch (message.type) {
            case 'start_meeting': {
                return this.handleStartMeeting(message.payload);
            }

            case 'join_meeting': {
                return this.handleJoinMeeting(message.payload);
            }

            case 'session_expired': {
                this.handleSessionExpired();
                break;
            }

            case 'scheduled_dialog_closed': {
                store.dispatch(setShowCalendarSchedule(false));
                break;
            }
            case 'calendar_find_time':
            case 'route_to_calendar': {
                history.push(RoutePath.Calendar);
                break;
            }
            case 'scheduled_dialog_opened': {
                store.dispatch(setShowCalendarSchedule(true));
                break;
            }

            default:
                break;
        }
        return false;
    }
    handleStartMeeting(payload: MeetingParams) {
        if (payload?.meetingNumber) {
            serviceContainer
                .getMeetingAgent()
                .startMeetingWithMeetingNumber(payload?.meetingNumber, { omn: payload?.originalMeetingNumber });
            return new Promise((resolve) => {
                const timer = setTimeout(() => {
                    resolve(false);
                }, 25000);

                const successId = eventBus.meeting.success.subscribe(() => {
                    clearTimeout(timer);
                    resolve(true);
                    eventBus.meeting.success.unsubscribe(successId);
                });
                const endId = eventBus.meeting.end.subscribe(() => {
                    clearTimeout(timer);
                    resolve(false);
                    eventBus.meeting.end.unsubscribe(endId);
                });
            });
        }
        console.warn('meeting number is not exist !');
        return Promise.resolve(false);
    }
    handleJoinMeeting(payload: MeetingParams) {
        if (payload?.meetingNumber) {
            serviceContainer.getMeetingAgent().joinMeeting(payload.meetingNumber, { pwd: payload.meetingPassword });
            return new Promise((resolve) => {
                const id = eventBus.meeting.success.subscribe(() => {
                    resolve(true);
                    eventBus.meeting.success.unsubscribe(id);
                });
            });
        }
        console.warn('meeting number is not exist !');
        return Promise.resolve(false);
    }

    notifyToDestroy() {
        microApp.setData(CALENDAR_APP_NAME, {
            type: 'session_expired',
        });
    }

    handleSessionExpired() {
        store.dispatch(signoutWhenSeesionExpiredThunk());
        userSessionLogger.log('', ['Session_Expire', 'From_Calendar']);
    }

    showCalendarScheduleDialog() {
        microApp.setData(CALENDAR_APP_NAME, {
            type: 'show_schedule_dialog',
            date: new Date(),
        });
    }

    hideCalendarScheduleDialog() {
        microApp.setData(CALENDAR_APP_NAME, {
            type: 'hide_schedule_dialog',
            date: new Date(),
        });
        store.dispatch(setShowCalendarSchedule(false));
    }
}
