import React from 'react';
import OverlayTrigger, { OverlayTriggerProps, OverlayTriggerType } from 'react-bootstrap/OverlayTrigger';
import './Tooltip.scss';
import ToolTipBackgroundSvg from '../../assets/images/tooltip-arrow-down.svg';

interface IProps extends Omit<OverlayTriggerProps, 'overlay'> {
    tooltip: string;
    className?: string;
    skidding?: number;
    distance?: number;
    id?: string;
}

export default function Tooltip(props: IProps) {
    const { children, tooltip, id = '', skidding = 0, distance = 0, className = '', ...restProps } = props;

    if (!tooltip) return null;

    if (!restProps.placement) {
        restProps.placement = 'top';
    }

    if (!restProps.trigger) {
        restProps.trigger = ['focus', 'hover'] as Array<OverlayTriggerType>;
    }

    if (skidding || distance) {
        if (!restProps.popperConfig) {
            restProps.popperConfig = {};
        }

        if (!restProps.popperConfig.modifiers) {
            restProps.popperConfig.modifiers = [];
        }

        restProps.popperConfig.modifiers.push({
            name: 'offset',
            options: {
                offset: [skidding, distance],
            },
        });
    }

    const renderToolTip = (props: any) => {
        const cls = `pwa-tooltip ${className}`;
        return (
            <div
                role="tooltip"
                id={id}
                className={cls}
                ref={props.ref}
                style={props.style}
                data-popper-escaped={props['data-popper-escaped']}
                data-popper-placement={props['data-popper-placement']}
                data-popper-reference-hidden={props['data-popper-reference-hidden']}
            >
                <img className="pwa-tooltip__bg" alt="" src={ToolTipBackgroundSvg} />
                <div className="pwa-tooltip__inner">{tooltip}</div>
            </div>
        );
    };

    return (
        <OverlayTrigger overlay={renderToolTip} {...restProps}>
            {children}
        </OverlayTrigger>
    );
}
