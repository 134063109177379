import { BrowserDetect } from '..';

const chromePolicyKeys = [
    'DisableGoogleLogin',
    'DisableFacebookLogin',
    'DisableLoginWithSSO',
    'DisableLoginWithEmail',
    'DisableAppleLogin',
    'ForceLoginWithSSO',
    'SetSSOURL',
    'SSOLoginRenameDisabled',
    'GoogleLoginRenameDisabled',
    'SetAccountIDsRestrictedToJoin',
    'BandwidthLimitUp',
    'BandwidthLimitDown',
    'AutoLoginWithChromeBookUserAccount',
];

/**
 * on chromeos we need to get policy set by  current user's admin.
 * https://github.com/WICG/WebApiDevice
 */
export async function getChromeOSAdminPolicy(): Promise<Record<string, any> | null> {
    if (!BrowserDetect.isChromeOS()) {
        return null;
    }
    const _navigator: any = window.navigator; // dismiss typescript complaints
    if (!_navigator.managed || typeof _navigator.managed.getManagedConfiguration !== 'function') {
        return null;
    }
    try {
        return await _navigator.managed.getManagedConfiguration(chromePolicyKeys);
    } catch (e) {
        console.log(e);
        return null;
    }
}
