import React from 'react';
import styles from './index.module.scss';
import { openTab } from '../../../utils';
import { Learn_About_Phone, Phone_Contact_Admin } from '../../../resource';
import { useAppSelector } from '../../../store';
import {
    getBuyZoomPhonePlanUrl,
    getLearnZoomPhoneUrl,
    getZoomPhoneModuleState,
    getZoomPhoneSetUpUrl,
    isFalse,
} from './utils';
import { shallowEqual } from 'react-redux';
import { isAppLoadedFromZoomPhoneRoute } from '../../../services/http/zoomPhone';

const onClick = () => {
    openTab(getLearnZoomPhoneUrl());
};

export default function CanNotShowBanner() {
    const { hasZoomPhonePlan } = useAppSelector(getZoomPhoneModuleState, shallowEqual);
    const setupUrl = getZoomPhoneSetUpUrl();
    const buyPlanUrl = getBuyZoomPhonePlanUrl();

    const show = isFalse(hasZoomPhonePlan) && !setupUrl && !buyPlanUrl && isAppLoadedFromZoomPhoneRoute();

    if (!show) {
        return null;
    }

    return (
        <div className={styles.banner}>
            <span>{Phone_Contact_Admin}</span>
            <span className={styles.link} onClick={onClick}>
                {Learn_About_Phone}
            </span>
        </div>
    );
}
