import React from 'react';
import './Meetings/Meetings.scss';

export default function Loading() {
    return (
        <div className="loading-container">
            <i className="z-loading"></i>
        </div>
    );
}
