import React from 'react';
import { Meetings_Empty_Tips_Text } from '.././../LanguageResource';

export default function EmptyDetail() {
    return (
        <div className="meetings__detail-empty">
            <div className="meetings__detail-empty-content">
                <span className="meetings-empty-icon"></span>
                <span className="meetings-empty-tips">{Meetings_Empty_Tips_Text}</span>
            </div>
        </div>
    );
}
