import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { TextItem, MailItem, PhoneItemView } from './ContactInfoItem';
import {
    CONTACTS_EMAIL,
    CONTACTS_COMPANY,
    CONTACTS_DEPARTMENT,
    CONTACTS_JOB_TITLE,
    OTP_LOCATION,
    CONTACTS_TIME_ZONE,
    CONTACTS_EXTENSION,
    Direct_Number_Text,
    CONTACTS_MOBILE,
    ABOUT_BUTTON,
    CONTACTS_OTHER,
} from '../../../resource';
import { IContact, IContact_Profile_Ext, IContact_Profile_WKphone } from '../types';

dayjs.extend(utc);
dayjs.extend(timezone);

const getGMT = (ext: IContact_Profile_Ext) => {
    if (!ext?.info?.timezoneid) {
        return '';
    }

    const timezoneid = ext.info.timezoneid;
    //format such as : 2022-09-07T03:59:04-05:00、2022-09-07T16:59:09+08:00
    const timeDiff = dayjs.tz(dayjs(), timezoneid).format().slice(19);
    return `(GMT${timeDiff}) ${timezoneid}`;
};

const getWKPhoneLabels = (wkphones: Array<IContact_Profile_WKphone>, props: { isIMBlocked: boolean, contact: IContact }) => {
    if (!wkphones) return null;

    return wkphones.map((item) => {
        //  label: one of Home \ Fax \ Phone \ Mobile
        const { code, label, text, country } = item;
        let content = text;
        if (code) content = code + text;
        return (
            <PhoneItemView
                key={text}
                title={label || CONTACTS_OTHER}
                content={content}
                region={country}
                isIMBlocked={props.isIMBlocked}
                contact={props.contact}
            />
        );
    });
};

interface IProps {
    contact: IContact;
    isIMBlocked: boolean;
}

export default function ContactInfoList ({ contact, isIMBlocked }: IProps) {
    const { email, intro, ext, directNumber, extension, phone, wkphones, isExternal } = contact;
    const timeZone = getGMT(ext);

    return (
        <div className="contact-detail__infolist">
            {ext?.cmpy && <TextItem title={CONTACTS_COMPANY} content={ext.cmpy} />}
            {ext?.dept && <TextItem title={CONTACTS_DEPARTMENT} content={ext.dept} />}
            {ext?.job && <TextItem title={CONTACTS_JOB_TITLE} content={ext.job} />}
            {ext?.adr && <TextItem title={OTP_LOCATION} content={ext.adr} />}
            {timeZone && <TextItem title={CONTACTS_TIME_ZONE} content={timeZone} />}
            {extension && !isExternal && (
                <PhoneItemView
                    title={CONTACTS_EXTENSION}
                    content={extension}
                    key={extension}
                    isIMBlocked={isIMBlocked}
                    contact={contact}
                />
            )}
            {directNumber && Array.isArray(directNumber) && directNumber.length > 0 && (
                <PhoneItemView title={Direct_Number_Text} content={directNumber} isIMBlocked={isIMBlocked} contact={contact}/>
            )}
            {phone?.normal && (
                <PhoneItemView
                    title={CONTACTS_MOBILE}
                    content={phone.normal}
                    key={phone.normal}
                    isIMBlocked={isIMBlocked}
                    contact={contact}
                />
            )}
            {getWKPhoneLabels(wkphones, { isIMBlocked, contact })}
            {email && <MailItem title={CONTACTS_EMAIL} content={email} />}
            {intro ? (
                <div className="contact-detail__info">
                    <span className="contact-detail__info-left">{ABOUT_BUTTON}</span>
                    <span className="contact-detail__info-right">{intro}</span>
                </div>
            ) : null}
        </div>
    );
}
