import React from 'react';
import copy from 'copy-to-clipboard';
import { Dropdown } from 'react-bootstrap';
import { CalendarEvent, MeetingItem } from '../Meetings/types';
import Button from '../../../components/Button/Button';
import { useAppDispatch } from '../../../store/store-hooks';
import { setSelectedMeetingItemId } from '../Meetings/redux/meetings-store';
import { RoutePath } from '../../../routes/routePath';
import { useHistory } from 'react-router-dom';
import useMeetingItem from '../Meetings/useMeetingItem';
import useMeetingItemState from '../Meetings/useMeetingItemState';
import { View_Text } from '../LanguageResource';
import { IconEllipsesHorizontalSmall } from '@zoom/icons-react';

interface IProps {
    data: MeetingItem | CalendarEvent;
}

export default function UpcomingActions({ data }: IProps): JSX.Element {
    const { id } = data;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const meetingItemProperties = useMeetingItem({ item: data });
    const {
        canCopy,
        copyTxt,
        copyHandler,
        canStart,
        startTxt,
        startHandler,
        canJoin,
        joinTxt,
        joinHandler,
        canEdit,
        editTxt,
        editHandler,
        canDelete,
        deleteTxt,
        deleteHandler,
        canViewOnZoomEvent,
        viewOnZoomEventTxt,
        viewOnZoomEventHandler,
    } = meetingItemProperties;

    const { disableDelete, disableEdit, disableJoin, disableStart } = useMeetingItemState(meetingItemProperties);

    const toView = () => {
        dispatch(setSelectedMeetingItemId(id));
        history.push(RoutePath.Meetings);
    };

    const bShowMore = canViewOnZoomEvent || canCopy || canEdit || canDelete;
    let btnTxt = '';
    let btnHandler = null;
    let btnType = Button.Secondary;
    let btnDisabled = false;
    if (canStart) {
        btnTxt = startTxt;
        btnHandler = startHandler;
        btnDisabled = disableStart;
        btnType = Button.Normal;
    } else if (canJoin) {
        btnType = Button.Normal;
        btnTxt = joinTxt;
        btnHandler = joinHandler;
        btnDisabled = disableJoin;
    } else if (canViewOnZoomEvent) {
        btnTxt = viewOnZoomEventTxt;
        btnHandler = viewOnZoomEventHandler;
    } else {
        btnType = Button.Normal;
        btnTxt = View_Text;
        btnHandler = toView;
    }

    const onClickCopy = () => {
        copyHandler().then((invitation) => {
            copy(invitation);
        });
    };

    return (
        <div className="upcoming__actions">
            {bShowMore ? (
                <Dropdown id="upcoming-actions__dropdown">
                    <Dropdown.Toggle id="upcoming__actions__toogle" as="div">
                        <Button
                            size={Button.Size24}
                            type={Button.Secondary}
                            extraCls="upcoming__detail-btn-more"
                            LeftIcon={<IconEllipsesHorizontalSmall />}
                        ></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="start">
                        {canViewOnZoomEvent ? (
                            <Dropdown.Item onClick={viewOnZoomEventHandler}>{viewOnZoomEventTxt}</Dropdown.Item>
                        ) : null}
                        {canCopy ? <Dropdown.Item onClick={onClickCopy}>{copyTxt}</Dropdown.Item> : null}
                        {canEdit ? (
                            <Dropdown.Item onClick={editHandler} disabled={disableEdit}>
                                {editTxt}
                            </Dropdown.Item>
                        ) : null}
                        {canDelete ? (
                            <Dropdown.Item onClick={deleteHandler} disabled={disableDelete}>
                                {deleteTxt}
                            </Dropdown.Item>
                        ) : null}
                    </Dropdown.Menu>
                </Dropdown>
            ) : null}

            <Button size={Button.Size24} type={btnType} onClick={btnHandler} disabled={btnDisabled}>
                {btnTxt}
            </Button>
        </div>
    );
}
