import zoomLogo from '../assets/images/zoom-logo.png';

export enum NOTIFICATION_TAGS {
    DECLINE_UNAVAILABLE = 'decline_unavailable',
    DECLINE_MISSING = 'decline_missing',
    INCOMING_SMS = 'incoming_sms',
    MEETING_INVITATION = 'meeting-invitation',
    PHONE_CALL = 'phone-call',
    INVITE_CONTACT = 'invite_contact',
    ONE_TIME_PASSWORD = 'otp-notification',
}

interface NotifyParams extends NotificationOptions {
    title?: string;
    tag: NOTIFICATION_TAGS;
    onClick?: ((params: Notification) => any) | null;
    onClose?: ((params: Notification) => any) | null;
    renotify?: boolean;
}

class BrowserNotification {
    static isSupport() {
        return typeof window.Notification === 'function';
    }

    static requestPermission = () => {
        if (!BrowserNotification.isSupport()) return false;

        if (Notification.permission === 'granted') {
            return true;
        }
        if (Notification.permission === 'denied') {
            return false;
        }
        Notification.requestPermission();
        return false;
    };

    defaultOptions = {
        renotify: true,
        requireInteraction: true,
        icon: zoomLogo,
    };

    options: NotificationOptions;

    constructor(options = {}) {
        this.options = {
            ...this.defaultOptions,
            ...options,
        };
    }

    getPermission() {
        if (!BrowserNotification.isSupport()) return null;
        return window.Notification.permission;
    }

    notify({ body, title, onClick, onClose, tag, ...rest }: NotifyParams) {
        if (!BrowserNotification.isSupport() || 'granted' !== window.Notification.permission) {
            return null;
        }

        const _options = {
            ...this.options,
            ...rest,
            tag,
            body,
        };

        const _notification = new window.Notification(title ? title : '', _options);
        if (onClick) {
            _notification.onclick = () => onClick(_notification);
        }
        if (onClose) {
            _notification.onclose = () => onClose(_notification);
        }
        return _notification;
    }
}

export default BrowserNotification;
